<template>
    <div id="kf" @click="toKF">
        <i :style="'background-image:url('+$store.state.imagePath.kf+')'"></i>
        <span>在线客服</span>
    </div>
</template>
<script>
//import * as kf from '../assets/js/kf'

export default {
	data () {
		return {
			showBox: false
		};
	},
	components: {},
	computed: {},
	mounted: function () {
	},
	methods: {
//		toKF: function () {
//			kf.open();
//			setTimeout(() => {
//				let dom = document.getElementById('kf-icon-close');
//				dom.onclick = () => {
//					kf.close();
//				}
//			}, 600)
//		}
		toKF: function () {
			let dom = document.createElement('a');
			dom.href = 'http://fw.sq581.com/index/index/home?theme=7571f9&visiter_id=&visiter_name=&avatar=&business_id=1&groupid=0';
			dom.target = '_blank';
			dom.click();
			setTimeout(() => {
				dom.remove();
			}, 300)
		}
	}
};
</script>
<style lang="scss" scoped>
    #kf {
        width: 50px; height: 50px; display: inline-block; box-shadow: 0 10px 15px -2px rgba(181, 197, 251, 1); position: fixed; right: 40px; bottom: 240px; z-index: 100; background-color: #2C59F3; border-radius: 2px; cursor: pointer;
        font-size: 0;
        i {
            width: 30px; height: 30px; display: inline-block; margin-left: 10px; margin-top: 4px;
        }
        span {
            width: 100%; font-size: 12px; color: #ffffff; height: 20px; line-height: 20px; text-align: center; display: inline-block; transform: scale(0.78); transform-origin: top center; margin-top: -2px;
        }
    }
</style>
<style>
</style>
