<template>
    <div id="oneCheckLogin" ref="box" @scroll="onScroll" @touchmove="onScroll">
        <div class="banner">
            <div class="banner_box_slide auto_padding"
                 :style="'background-image:url('+$store.state.imagePath.banner2+');'">
                <div class="box ">
                    <transition name="fade" mode="out-in">
                        <div class="content">
                            <div class="title">全球短信</div>
                            <div class="info">全网全球发送，同时支持最新的虚拟运营商号段<br>一账户配多条通道，保证短信持续稳定发送<br>支持Web/H5/App多端API一键接入


                            </div>
                            <el-button type="primary" class="immediate_use"
                                       @click="$router.push({path:'/documentCenter/shortMessage/file'})">立即使用


                            </el-button>
                            <i class="right_img"
                               :style="'background-image:url('+$store.state.imagePath.banner_1+');'"></i>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <!-- 应用场景 -->
        <div class="application_scenarios auto_padding lazy_box">
            <div class="the_title">
                <span class="no_subtitle_main_title">应用场景</span>
            </div>
            <div class="content">
                <ul>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_1+')'"></i>
                        <span class="name">注册登录</span>
                        <span class="tips">三网合一的高质量<br>专用通道</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_5+')'"></i>
                        <span class="name">系统通知</span>
                        <span class="tips">适用于物流通知，消费通知<br>支付通知，系统通知</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_6+')'"></i>
                        <span class="name">营销推广</span>
                        <span class="tips">适用于电商活动<br>新品宣传、会员服务等</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_7+')'"></i>
                        <span class="name">促销通知</span>
                        <span class="tips">新品上线<br>快速通知</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 产品优势 -->
        <div class="advantage_product auto_padding lazy_box">
            <div class="the_title">
                <span class="no_subtitle_main_title">产品优势</span>
            </div>
            <div class="content">
                <ul>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_13+')'"></i>
                        <span class="name">5S内送达</span>
                        <span class="tips">专用短信通道<br>分布式集群架构<br>服务请求毫秒级响应</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_14+')'"></i>
                        <span class="name">99.99%到达率</span>
                        <span class="tips">支持大容量、高并发<br>独家真机监控<br>实时监控自动切换</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_7+')'"></i>
                        <span class="name">稳定保障</span>
                        <span class="tips">多通道组互备<br>自动切换</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_8+')'"></i>
                        <span class="name">专业团队</span>
                        <span class="tips">专业运维团队<br>完整内审内控机制</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 产品价格 -->
        <div class="product_price auto_padding lazy_box">
            <div class="the_title">
                <span class="no_subtitle_main_title">产品价格</span>
            </div>
            <div class="content">
                <ul>
                    <li>
                        <div class="search_box">
                            <el-select v-model="type" class="mini_select" @change="handleChangeType">
                                <el-option v-for="item in typeList"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                            <el-select v-model="name" filterable placeholder="国家" popper-class="price_select_popper"
                                       @change="toSendMessage">
                                <el-option
                                        v-for="item in priceList"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                    <span class="zone">{{'+' + Math.abs(item.zone)}}</span>
                                    <span class="name_zh">{{item.zh}}<span class="name_en on">{{item.en}}</span></span>
                                    <span class="price">{{item.price + '&nbsp;&nbsp;CNY'}}</span>
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-select>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
        <!-- 接入指引 -->
        <div class="access_guidelines auto_padding lazy_box"
             :style="'background-image:url('+$store.state.imagePath.comment_bg+')'">
            <div class="the_title">
                <span class="no_subtitle_main_title">接入指引</span>
            </div>
            <div class="content">
                <ul class="step">
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_9+')'"></i>
                        <span class="name" :class="auto_number === 1 ? 'on' : ''">1<i class="line"></i></span>
                        <span class="tips">注册/登录</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_10+')'"></i>
                        <span class="name" :class="auto_number === 2 ? 'on' : ''">2<i class="line"></i></span>
                        <span class="tips">后台创建应用</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_11+')'"></i>
                        <span class="name" :class="auto_number === 3 ? 'on' : ''">3<i class="line"></i></span>
                        <span class="tips">创建签名和模板<br>审核通过后即可发送</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_12+')'"></i>
                        <span class="name" :class="auto_number === 4 ? 'on' : ''">4</span>
                        <span class="tips">接入短信API<br>调试发送</span>
                    </li>
                </ul>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <bottom-column></bottom-column>
        </transition>
    </div>
</template>
<script>
import banner from '../components/banner';
import bottomColumn from '../components/bottomColumn';

export default {
	data () {
		return {
			type: 'market',
			name: '',
			hot_product_active_index: 'h_1_1',
			myECharts: '',
			priceList: [],
			auto_number: 1,
			si: '',
			typeList: [{
				label: '营销短信',
				value: 'market'
			}, {
				label: '验证码',
				value: 'sms'
			}]
		};
	},
	computed: {
		box: function () {
			return this.$refs.box;
		}
	},
	components: {banner, bottomColumn},
	created: function () {
		this.handleChangeType('market');
	},
	mounted () {
		this.onScroll();
	},
	methods: {
		onScroll: function () {
			let _this = this;
			let lazy_box = document.querySelectorAll('.lazy_box');
			this.box.scrollTop > 66 ? this.$store.commit('toggleShowTitleBarBackgroundColor', true) : this.$store.commit('toggleShowTitleBarBackgroundColor', false);
			lazy_box.forEach(function (item) {
				if (item.offsetTop < (_this.box.scrollTop + document.documentElement.clientHeight) - 300) {
					if (item.classList.value.indexOf('access_guidelines') >= 0 && !_this.si) {
						_this.autoChangeActiveIndex();
					}
					item.classList.add('on');
				} else {
					if (item.classList.value.indexOf('access_guidelines') >= 0 && _this.si) {
						_this.auto_number = 1;
						clearInterval(_this.si);
						_this.si = '';
					}
					item.classList.remove('on');
				}
			});
		},
		autoChangeActiveIndex: function () {
			let _this = this;
			this.si = setInterval(function () {
				_this.auto_number = (_this.auto_number + 1 > 4) ? 1 : _this.auto_number + 1;
			}, 3000);
		},
		toSendMessage: function () {
			setTimeout(() => {
				window.open('http://sms.sq581.com');
			}, 600);
		},
		handleChangeType: function (value) {
			switch (value) {
				case 'market':
					this.Api.getMarketPrice((data) => {
						let list = [];
						if (data.length) {
							data.forEach((item) => {
								item['label'] = Math.abs(item.zone) + ' ' + item.zh + ' ' + item.en;
								list.push(item);
							});
						}
						this.priceList = list;
					});
					break;
				case 'sms':
					this.Api.getSMSPrice((data) => {
						let list = [];
						if (data.length) {
							data.forEach((item) => {
								item['label'] = Math.abs(item.zone) + ' ' + item.zh + ' ' + item.en;
								list.push(item);
							});
						}
						this.priceList = list;
					});
					break;
			}
		}

	},
	watch: {},
	destroyed: function () {
		clearInterval(this.si);
	}
};
</script>
<style lang="scss" scoped>
    div#oneCheckLogin {
        width: 100%; min-width: 1200px; height: 100%; display: block; overflow-x: hidden; overflow-y: auto; font-size: 0; position: relative;
        .banner {
            width: 100%; height: 690px; overflow: hidden; position: relative;
            div.banner_box_slide {
                width: 100% !important; height: 100%; background-size: cover; display: inline-block; vertical-align: top; overflow: hidden; white-space: pre-wrap; background-color: #ffffff; position: relative;
                div.box {
                    width: 100%; height: 100%; overflow: hidden; position: relative;
                    div.content {
                        width: calc(100% - 52px); margin-top: 187px; margin-left: 26px; margin-right: 26px; transition: opacity 0.6s;
                        div.title {
                            font-size: 42px; color: #ffffff; letter-spacing: 2px; line-height: 1;
                        }
                        div.info {
                            margin-top: 60px; font-size: 24px; color: #ffffff; letter-spacing: 0.8px; line-height: 1.7;
                        }
                        .immediate_use {
                            font-size: 18px; height: 52px; padding: 13px 43px 13px 47px; margin-top: 60px; letter-spacing: 4px; box-sizing: border-box;
                        }
                        i.right_img {
                            width: 500px; height: 468px; position: absolute; top: 124px; right: 30px; display: inline-block;
                        }
                    }
                    div.content.on {
                        opacity: 1;
                    }
                    div.content.on {
                        opacity: 0;
                    }
                }
            }
        }
        div.the_title {
            position: relative; height: 180px;
            span.bg_font {
                height: 180px; line-height: 180px; position: absolute; top: 0; left: 0; right: 0; text-align: center; font-family: Oswald-DemiBold; font-size: 150px; color: #F8F8F8;
            }
            span.main_title {
                margin-top: 58px; width: 100%; font-size: 32px; font-weight: 600; color: #333333; text-align: center; line-height: 45px; display: inline-block; position: relative; z-index: 1;
            }
            span.subtitle {
                width: 100%; font-size: 24px; color: #666666; text-align: center; line-height: 33px; display: inline-block; position: relative; z-index: 1;
                em { width: 20px; display: inline-block; }
            }
            span.no_subtitle_main_title {
                margin-top: 68px; width: 100%; font-size: 32px; font-weight: 600; color: #333333; text-align: center; line-height: 45px; display: inline-block; position: relative; z-index: 1;
            }
        }
        div.application_scenarios {
            position: relative;
            div.content {
                padding: 0 0;
                ul {
                    width: 100%; height: auto;
                    li {
                        width: 258px; height: auto; display: inline-block; vertical-align: top; background-color: transparent; margin: 0 20px; position: relative; transition-timing-function: ease-out; transition-duration: 0.2s;
                        i.icon {
                            width: 150px; height: 118px; display: block; margin-left: calc((100% - 150px) / 2);
                        }
                        span.name {
                            font-size: 24px; width: 100%; line-height: 33px; text-align: center; margin-top: 34px; display: inline-block;
                        }
                        span.tips {
                            font-size: 14px; width: 100%; height: 88px; line-height: 25px; text-align: center; margin-top: 18px; display: inline-block;
                        }
                    }
                }
            }
        }
        div.advantage_product {
            position: relative; background-image: linear-gradient(to bottom, #FFFFFF, #FBFCFE, #F7F9FC);
            div.content {
                padding: 0 0 120px 0;
                ul {
                    width: 100%; height: auto;
                    li {
                        width: 258px; height: 356px; display: inline-block; vertical-align: top; background-color: #ffffff; margin: 0 20px; position: relative; box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.05); transition-timing-function: ease-out; transition-duration: 0.2s;
                        i.icon {
                            width: 150px; height: 150px; display: block; margin-left: calc((100% - 150px) / 2); margin-top: 28px;
                        }
                        span.name {
                            font-size: 24px; width: 100%; line-height: 33px; text-align: center; margin-top: 14px; display: inline-block;
                        }
                        span.tips {
                            font-size: 14px; width: 100%; line-height: 25px; text-align: center; margin-top: 18px; display: inline-block;
                        }
                    }
                    li:hover {
                        transform: translate3d(0, -16px, 0);
                    }
                }
            }
        }
        div.product_price {
            position: relative;
            div.content {
                padding: 0 0 70px 0;
                ul {
                    width: 100%; height: auto;
                    li {
                        width: 100%; height: 300px; box-sizing: border-box; padding: 70px 0; text-align: center;
                    }
                }
            }
        }
        div.access_guidelines {
            position: relative; height: 530px;
            div.content {
                padding: 0 0;
                ul {
                    width: 100%; height: auto;
                    li {
                        width: 258px; height: auto; display: inline-block; vertical-align: top; background-color: transparent; margin: 0 20px; text-align: center; position: relative; transition-timing-function: ease-out; transition-duration: 0.2s;
                        i.icon {
                            width: 118px; height: 118px; display: block; margin-left: calc((100% - 118px) / 2);
                        }
                        span.name {
                            position: relative; width: 36px; height: 36px; line-height: 36px; font-size: 24px; text-align: center; margin-top: 38px; display: inline-block; border: 1px solid #979797; border-radius: 100%; box-sizing: border-box; transition: background-color 0.6s, color 0.6s; overflow: visible;
                            i.line {
                                position: absolute; left: calc(36px + 40px); top: 17px; background-color: #d8d8d8; display: inline-block; width: 182px; height: 2px;
                            }
                        }
                        span.tips {
                            font-size: 18px; width: 100%; height: auto; line-height: 32px; text-align: center; margin-top: 20px; display: inline-block;
                        }
                        span.name.on {
                            background-color: #979797; color: #ffffff;
                        }
                    }
                }
            }
        }
        .lazy_box {
            div.content {
                transition: opacity 0.3s; opacity: 0;
            }
        }
        .lazy_box.on {
            div.content {
                opacity: 1;
            }
        }
        .search_box {
            width: 100%; min-width: 480px; max-width: 722px; height: auto; display: inline-block;
            ::v-deep {
                .el-select {
                    width: calc(100% - 120px);
                }
                .el-input__inner {
                    height: 60px; line-height: 60px; padding-left: 60px; border-top-left-radius: 0; border-bottom-left-radius: 0;
                }
                .el-input__prefix {
                    left: 20px;
                    i {
                        line-height: 60px;
                    }
                }
                .mini_select {
                    width: 120px; display: inline-block; vertical-align: top;
                    .el-input__inner {
                        height: 60px; line-height: 60px; padding-left: 20px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; border-top-right-radius: 0; border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">

</style>
