<template>
    <div id="shortMessageDownload">
        <div class="title">资源下载</div>
        <div class="content">
            <ul>
                <li>
                    <i :style="'background-image:url('+$store.state.iconPath.document_center_11+');'"></i>
                    <div class="box">
                        <span>全球短信API文档</span>
                        <el-button type="primary" class="download_btn" @click="download('smsService','sdkDownUrl')">点击下载
                        </el-button>
                    </div>
                </li>
                <li>
                    <i :style="'background-image:url('+$store.state.iconPath.document_center_10+');'"></i>
                    <div class="box">
                        <span>全球短信Demo</span>
                        <el-button type="primary" class="download_btn" @click="download('smsService','demoDownUrl')">
                            点击下载
                        </el-button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>

export default {
    data () {
        return {
            index: ''
        };
    },
    computed: {},
    components: {},
    mounted () {

    },
    methods: {
        download: function (key, name) {
            let a = document.createElement('a');
            a.href = this.$store.state.documentCenterData[key][name];
            a.download = '';
            a.click();
        }
    },
    watch: {}
};
</script>
<style lang="scss" scoped>
    #shortMessageDownload {
        width: 100%; height: 100%; display: block; overflow: auto; font-size: 0; padding: 0 0; box-sizing: border-box;
        .title {
            padding: 40px 50px; font-size: 24px; width: 100%; line-height: 34px; color: #333; box-sizing: border-box;
        }
        .content {
            width: 100%; height: auto; padding: 10px 0; box-sizing: border-box;
            ul {
                width: 100%; height: auto;
                li {
                    width: 320px; height: 152px; display: inline-block; vertical-align: top; padding: 0 0; box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.06); border-radius: 4px; margin: 0 0 30px 50px; text-align: left;
                    > i {
                        display: inline-block; width: 80px; height: 80px; margin-top: 36px;margin-left: 30px;
                    }
                    div.box {
                        width: calc(100% - 110px);height: 100%;display: inline-block;vertical-align: top;
                        > span {
                            width: 100%; line-height: 36px; font-size: 18px; color: #333333; padding-left: 24px;margin-top: 36px; display: inline-block;vertical-align: top;box-sizing: border-box;
                        }
                        .download_btn {
                            font-size: 14px; height: 34px; line-height: 32px; padding: 0 16px;margin-top: 10px; margin-left: 24px; background-color: transparent; color: #333; border-color: #999;display: inline-block;
                        }
                        .download_btn:hover {
                            background-color: #2C59F3; color: #fff; border-color: #2C59F3;
                        }
                    }
                }
            }
        }
    }
</style>
<style lang="scss">

</style>
