import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const STATIC_PATH = '';

const store = new Vuex.Store({
	state: {
		loading: false,
		imagePath: {
			logo: STATIC_PATH + '/images/logo.svg',
			logo_: STATIC_PATH + '/images/logo123.png',
			banner1: STATIC_PATH + '/images/banner1.jpg',
			banner2: STATIC_PATH + '/images/banner2.jpg',
			banner3: STATIC_PATH + '/images/banner3.jpg',
			banner4: STATIC_PATH + '/images/banner4.jpg',
			banner_1: STATIC_PATH + '/images/banner_1.png',
			banner_2: STATIC_PATH + '/images/banner_2.png',
			banner_3: STATIC_PATH + '/images/banner_3.png',
			product_0: STATIC_PATH + '/images/product_0.svg',
			product_1: STATIC_PATH + '/images/product_1.svg',
			product_2: STATIC_PATH + '/images/product_2.svg',
			product_3: STATIC_PATH + '/images/product_3.svg',
			product_4: STATIC_PATH + '/images/product_4.svg',
			product_5: STATIC_PATH + '/images/product_5.svg',
			comment_bg: STATIC_PATH + '/images/comment_bg.png',
			qr_code: STATIC_PATH + '/images/qr_code.jpg',
			about_us_bg: STATIC_PATH + '/images/about_us_bg.jpg',
			logo_1: STATIC_PATH + '/images/logo_1.png',
			logo_2: STATIC_PATH + '/images/logo_2.png',
			logo_3: STATIC_PATH + '/images/logo_3.png',
			logo_4: STATIC_PATH + '/images/logo_4.png',
			logo_5: STATIC_PATH + '/images/logo_5.png',
			logo_6: STATIC_PATH + '/images/logo_6.png',
			foot: STATIC_PATH + '/images/foot.jpg',
			wx: STATIC_PATH + '/images/wx.svg',
			kf: STATIC_PATH + '/images/kf.png',
			box: STATIC_PATH + '/images/box.png',
			register_bg: STATIC_PATH + '/images/register_bg.png',
			register_bg_down: STATIC_PATH + '/images/register_bg_down.png',
			register_bg_up: STATIC_PATH + '/images/register_bg_up.png',
		},
		iconPath: {
			advantage_1: STATIC_PATH + '/images/advantage_1.svg',
			advantage_2: STATIC_PATH + '/images/advantage_2.svg',
			advantage_3: STATIC_PATH + '/images/advantage_3.svg',
			advantage_4: STATIC_PATH + '/images/advantage_4.svg',
			advantage_5: STATIC_PATH + '/images/advantage_5.svg',
			advantage_6: STATIC_PATH + '/images/advantage_6.svg',
			advantage_7: STATIC_PATH + '/images/advantage_7.svg',
			advantage_8: STATIC_PATH + '/images/advantage_8.svg',
			advantage_9: STATIC_PATH + '/images/advantage_9.svg',
			advantage_10: STATIC_PATH + '/images/advantage_10.svg',
			advantage_11: STATIC_PATH + '/images/advantage_11.svg',
			advantage_12: STATIC_PATH + '/images/advantage_12.svg',
			advantage_13: STATIC_PATH + '/images/advantage_13.svg',
			advantage_14: STATIC_PATH + '/images/advantage_14.svg',
			application_scenarios_1: STATIC_PATH + '/images/application_scenarios_1.svg',
			application_scenarios_2: STATIC_PATH + '/images/application_scenarios_2.svg',
			application_scenarios_3: STATIC_PATH + '/images/application_scenarios_3.svg',
			application_scenarios_4: STATIC_PATH + '/images/application_scenarios_4.svg',
			application_scenarios_5: STATIC_PATH + '/images/application_scenarios_5.svg',
			application_scenarios_6: STATIC_PATH + '/images/application_scenarios_6.svg',
			application_scenarios_7: STATIC_PATH + '/images/application_scenarios_7.svg',
			document_center_1: STATIC_PATH + '/images/document_center_1.svg',
			document_center_2: STATIC_PATH + '/images/document_center_2.svg',
			document_center_3: STATIC_PATH + '/images/document_center_3.svg',
			document_center_4: STATIC_PATH + '/images/document_center_4.svg',
			document_center_5: STATIC_PATH + '/images/document_center_5.svg',
			document_center_6: STATIC_PATH + '/images/document_center_6.svg',
			document_center_7: STATIC_PATH + '/images/document_center_7.svg',
			document_center_8: STATIC_PATH + '/images/document_center_8.svg',
			document_center_9: STATIC_PATH + '/images/document_center_9.svg',
			document_center_10: STATIC_PATH + '/images/document_center_10.svg',
			document_center_11: STATIC_PATH + '/images/document_center_11.svg',
			document_center_12: STATIC_PATH + '/images/document_center_12.svg',
			document_center_13: STATIC_PATH + '/images/document_center_13.svg',
			document_center_14: STATIC_PATH + '/images/document_center_14.svg',
			document_center_3_on: STATIC_PATH + '/images/document_center_3_on.svg',
			document_center_4_on: STATIC_PATH + '/images/document_center_4_on.svg',
			document_center_5_on: STATIC_PATH + '/images/document_center_5_on.svg',
			document_center_6_on: STATIC_PATH + '/images/document_center_6_on.svg',
			score: STATIC_PATH + '/images/score.png'
		},
		alertDialog: {
			isShow: false,
			type: 'success', // error,success
			name: ''
		},
		isShowTitleBarBackgroundColor: false,
		router: {
			transformName: '', // 路由切换动画效果名称
			transitionStatus: false
		},
		documentCenterData: '',
		isShowJumpLogin: false
	},
	mutations: {
		toggleShowLoading: function (state, data) {
			state.loading = data;
		},
		setAlertDialog: function (state, data) {
			for (let key in data) {
				state.alertDialog[key] = data[key];
			}
		},
		toggleShowTitleBarBackgroundColor: function (state, value) {
			state.isShowTitleBarBackgroundColor = value;
		},
		setDocumentCenterData: function (state, data) {
			state.documentCenterData = JSON.parse(JSON.stringify(data));
		},
		updateRouterTransitionStatus: function (state, value) {
			state.router.transitionStatus = value;
		},
		handleShowJumpLogin: function (state, data) {
			state.isShowJumpLogin = data
		}
	},
	actions: {},
	modules: {}
});

export default store;

function getUser () {
	let localStorageUser = JSON.parse(localStorage.getItem('user'));
	if (localStorageUser) {
		return localStorageUser;
	} else {
		return '';
	}
}
