<template>
    <div id="oneCheckLoginFile" ref="file_box">
        <div class="title">开发文档</div>
        <ul class="catalog">
            <li><i></i><span @click="toIndex('d_html_1')">Android文档</span></li>
            <li><i></i><span @click="toIndex('d_html_2')">iOS文档</span></li>
            <li><i></i><span @click="toIndex('d_html_3')">服务器文档</span></li>
        </ul>
        <div class="title" ref="d_html_1">-------- Android文档 --------</div>
        <div class="content markdown-body" v-html="d_html_1"></div>
        <div class="title" ref="d_html_2">-------- iOS文档 --------</div>
        <div class="content markdown-body" v-html="d_html_2"></div>
        <div class="title" ref="d_html_3">-------- 服务器文档 --------</div>
        <div class="content markdown-body" v-html="d_html_3"></div>
    </div>
</template>
<script>
const marked = require('marked');

export default {
    data () {
        return {
            d_html_1: '',
            d_html_2: '',
            d_html_3: ''
        };
    },
    computed: {
        document: function () {
            return this.$store.state.documentCenterData;
        },
        file_box: function () {
            return this.$refs.file_box;
        }
    },
    components: {},
    mounted () {
        if (this.document) {
            this.d_html_1 = marked(this.document.authAndroid.document);
            this.d_html_2 = marked(this.document.authIos.document);
            this.d_html_3 = marked(this.document.authService.document);
        }
    },
    methods: {
        toIndex: function (name) {
            let _this = this;
            this.timer = setInterval(function () {
                //获取滚动条距离顶部高度
                let osTop = _this.file_box.scrollTop;
                let H = _this.$refs[name].offsetTop - 66;
                let ispeed = Math.floor(H / 8);

                _this.file_box.scrollTop = osTop + ispeed;
                //到达顶部，清除定时器
                if (H - osTop <= ispeed) {
                    clearInterval(_this.timer);
                    _this.file_box.scrollTop = osTop + H - osTop;
                }
            }, 30);
        }
    },
    watch: {
        document: {
            deep: true,
            handler: function (newV, oldV) {
                if (this.document.hasOwnProperty('authAndroid')) {
                    this.d_html_1 = marked(this.document.authAndroid.document);
                }
                if (this.document.hasOwnProperty('authIos')) {
                    this.d_html_2 = marked(this.document.authIos.document);
                }
                if (this.document.hasOwnProperty('authService')) {
                    this.d_html_3 = marked(this.document.authService.document);
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    #oneCheckLoginFile {
        width: 100%; height: 100%; display: block; overflow: auto; font-size: 0; padding: 0 0; box-sizing: border-box;
        * {
            -moz-user-select: text; /* Firefox私有属性 */
            -webkit-user-select: text; /* WebKit内核私有属性 */
            -ms-user-select: text; /* IE私有属性(IE10及以后) */
            -khtml-user-select: text; /* KHTML内核私有属性 */
            -o-user-select: text; /* Opera私有属性 */
            user-select: text; /* CSS3属性 */
        }
        .title {
            padding: 40px 50px; font-size: 24px; width: 100%; line-height: 34px; color: #333; box-sizing: border-box;
        }
        .catalog {
            padding: 0 50px 40px 50px;
            li {
                font-size: 14px; height: 20px; display: block; margin-bottom: 12px;
                i {
                    width: 4px; height: 4px; display: inline-block; vertical-align: top; margin-right: 12px; background-color: #2c59f3; margin-top: 8px;
                }
                span {
                    width: auto; height: 20px; line-height: 20px; display: inline-block; vertical-align: top; border-bottom: 1px dashed #2c59f3; cursor: pointer;
                }
                span:hover {
                    color: #2c59f3 !important;
                }
            }
        }
        .content {
            width: 100%; height: auto; padding: 10px 50px; box-sizing: border-box;
        }
    }
</style>
<style lang="scss">

</style>
