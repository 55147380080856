<script>
export default {
	name: 'titleColumn',
	components: {},
	props: [],
	data: function () {
		return {
			activeIndex: 'index',
			isShowSubMenu: false
		};
	},
	computed: {
		logo: function () {
			return this.$store.state.imagePath.logo;
		}
	},
	watch: {
		$route: function () {
			this.updateActiveIndex();
		}
	},
	mounted: function () {
		this.updateActiveIndex();
	},
	methods: {
		handleSelect: function (path) {
			this.$router.push({path: path});
		},
		updateActiveIndex: function () {
			switch (this.$route.name) {
				case 'oneCheckLoginGuide':
				case 'oneCheckLoginFile':
				case 'oneCheckLoginDownload':
				case 'oneCheckLoginProblem':
				case 'shortMessageGuide':
				case 'shortMessageFile':
				case 'shortMessageDownload':
				case 'shortMessageProblem':
					this.activeIndex = '/documentCenter';
					break;
				case 'oneCheckLogin':
				case 'shortMessage':
					this.activeIndex = '/oneCheckLogin';
					break;
				default:
					this.activeIndex = this.$route.path;
					break;
			}
		},
		getStyle: function () {
			let style = '';
			switch (this.activeIndex) {
				case '/documentCenter':
					style = 'background-color: #02051C';
					break;
				default:
					style = '';
					break;
			}
			return style;
		},
		toLogin: function () {
			this.$store.commit('handleShowJumpLogin', true)
			// window.open('http://www.sq581.com/admin/');
		},
		toRegister: function () {
			this.$router.push({path: '/register'}); // register
		},
		toHome: function () {
			this.$router.push({path: '/'});
		}
	}
};
</script>
<template>
    <div id="titleColumn" :style="getStyle()" :class="isShowSubMenu ? 'on' : ''">
        <div class="title_left">
            <i class="logo" :style="'background-image:url('+logo+');'" @click="toHome"></i>
            <i class="split_line"></i>
            <div class="logo_name">
                有诉求，找创数<br>专业的企业服务
            </div>
        </div>
        <div class="title_center">
            <el-menu
                    :default-active="activeIndex"
                    class="l_el_menu"
                    mode="horizontal"
                    @select="handleSelect"
                    background-color="transparent"
                    text-color="#fff"
                    active-text-color="#fff"
                    :popper-append-to-body="false">
                <el-menu-item index="/">首页</el-menu-item>
                <el-submenu index="/oneCheckLogin"
                            popper-class="l_el_menu_popper"
                            :popper-append-to-body="false"
                            :hide-timeout="1">
                    <template slot="title">产品大全</template>
                    <el-menu-item index="/oneCheckLogin">一键登录</el-menu-item>
                    <el-menu-item index="/shortMessage">全球短信</el-menu-item>
                </el-submenu>
                <el-menu-item index="/documentCenter">文档中心</el-menu-item>
                <el-menu-item index="/aboutUs">关于我们</el-menu-item>
            </el-menu>
        </div>
        <div class="title_right">
            <span class="login_name" @click="toLogin">登录</span>
            <el-button type="primary" class="register_now" @click="toRegister">注册</el-button>
        </div>
    </div>
</template>
<style scoped lang="scss">
    #titleColumn {
        width: 100%; height: 66px; background-color: transparent; position: relative; color: #ffffff; transition: background-color 0.3s;
        div.title_left {
            width: 270px; height: 100%; display: inline-block; vertical-align: top; margin-left: 30px; text-align: left;
            > * { vertical-align: top; }
            i.logo {
                width: 84px; height: 30px; display: inline-block; position: relative; margin-top: 18px; background-size: contain; cursor: pointer;fill: red;
            }
            i.split_line {
                height: 30px; width: 0; margin-left: 15px; margin-top: 18px; border-left: 1px solid #ffffff; display: inline-block;
            }
            .logo_name {
                font-size: 13px; line-height: 18px; height: 36px; margin-left: 15px; margin-right: 26px; margin-top: 15px; display: inline-block;
            }
        }
        div.title_center {
            width: calc(100% - 600px); height: 100%; display: inline-block; vertical-align: top; padding: 0 calc((100% - 600px - 564px) / 2); box-sizing: border-box;
            > * { vertical-align: top; }
            .l_el_menu {
                height: 44px; border: 0; margin-top: 11px;
            }
        }
        div.title_right {
            width: 270px; height: 100%; display: inline-block; vertical-align: top; margin-right: 30px; text-align: right;
            > * { vertical-align: top; }
            .login_name {
                font-size: 14px; width: 90px; height: 34px; line-height: 32px; margin-top: 16px; text-align: center; display: inline-block; cursor: pointer; border: 1px solid #DCDFE6; box-sizing: border-box; border-radius: 2px;
            }
            .login_name:hover {
                color: #dddddd !important;
            }
            .register_now {
                font-size: 14px; width: 90px; height: 34px; line-height: 32px; margin-top: 16px; margin-left: 16px; margin-right: 26px; border: 1px solid #2C59F3; box-sizing: border-box; padding: 0 0; background-color: #2C59F3; border-radius: 2px;
            }
        }
    }

    #titleColumn:hover {
        background-color: #02051C !important;
    }

    #titleColumn.on {
        background-color: #02051C !important;
    }
</style>
<style lang="scss">
    #titleColumn {
        .l_el_menu {
            .el-menu-item {
                height: 44px; line-height: 44px; margin: 0 40px; padding: 0 0; font-size: 14px;
            }
            .el-submenu {
                height: 44px; line-height: 44px;
                .el-submenu__title {
                    height: 44px; line-height: 44px; margin: 0 40px; padding: 0 0; font-size: 14px;
                    .el-submenu__icon-arrow {
                        color: #ffffff;
                    }
                }
                .el-submenu__title:hover {
                    background-color: transparent !important; color: #dddddd !important;
                }
            }
            .el-menu-item:hover,
            .el-menu-item:active,
            .el-menu-item:focus {
                background-color: transparent !important; color: #dddddd !important;
            }
            .l_el_menu_popper {
                background-color: #02051C; width: 164px;
                .el-menu--popup {
                    padding: 10px 0; width: 100%; min-width: auto;
                    .el-menu-item {
                        width: calc(100% - 40px); height: 44px; line-height: 44px; font-size: 14px; color: #ffffff !important; padding: 0 0; box-sizing: border-box; min-width: auto;
                    }
                    .el-menu-item:hover,
                    .el-menu-item:active,
                    .el-menu-item:focus {
                        background-color: transparent !important; color: #5D80F8 !important;
                    }
                }
            }
        }
    }

</style>
