<script>
export default {
	name: 'bottomColumn',
	components: {},
	props: [],
	data: function () {
		return {
			clauseDialogVisible: false
		};
	},
	computed: {},
	watch: {},
	mounted: function () {

	},
	methods: {
		clauseHTML: function () {
			let html = [
				'<p>在您使用本服务前请充分阅读并理解本协议内容，在创建客户账户时，一旦您选择“同意”按钮，也代表您同意遵守本协议之所有约定并授权创数科技可以通过运营商进行针对个人信息的数据核验。如果您不接受本协议，请不要选择“同意”按钮，也不要进行注册、使用或以其它方式访问我们的服务。</p >',
				'<p>您对在您账户下的所有活动（无论是否获得授权）全权负责，包括您的客户数据及每个客户使用的质量和完整性。如果您的账户下有任何未经授权或滥用行为或者任何与服务相关的安全事件，您将立即通知我们。您认可并同意我们不会对您账户中任何未经授权或滥用行为负责。</p >',
				'<p>您对：（a）您的账户的保密性，以及用于启用您访问服务的任何密码、身份验证密钥或安全凭据的安全性负责;（b）您的员工，用户或允许您向其提供服务的任何第三方使用本服务负责；（c）所有在您账户下的活动，不论是您还是您的最终用户的活动负责。(d) 由于您未使用合理的安全预防措施而导致的任何未经授权的使用负责。</p >',
				'<p>服务限制。 您不得、也不能使得或允许最终用户或其他人（a）修改、变更服务或制造服务的衍生品；（b）对服务进行反汇编、反编译、反向工程、复制服务的任何部分，或应用其他任何程序来获得服务中所包含的任何软件的源代码；（c）分发、转售、再许可、或转让服务，除非本协议另有规定或经双方书面同意。</p >',
				'<p>禁止的行为。 访问或使用本服务，或允许他人访问或使用服务时，不得有下列行为：</p >',
				'<p>(a)违反任何地区、国家或国际上的法律法规及规定；</p >',
				'<p>(b)侵犯或侵害他人权益，包括但不限于隐私权或知识产权；</p >',
				'<p>(c)从事、鼓动、协助或允许他人进行任何违法、非法、侵权、有害或欺诈行为，包括不限于任何下述活动：以任何方式伤害或企图伤害未成年人、色情、非法赌博、违规搭建VPN、庞氏骗局、网络攻击、网络钓鱼或损害、私自拦截任何系统、程序或数据，未经许可监控服务数据或流量；</p >',
				'<p>(d)传输、提供、上传、下载、使用或再使用、散布或分发任何非法、侵权、攻击性、有害的内容或材料，包括但不限于在如下“禁止的内容”中所列的内容或材料；</p >',
				'<p>(e)传输任何数据、发送或上传任何包含病毒、蠕虫、特洛伊木马、网络定时炸弹、间谍软件、广告软件或任何其他有害程序或类似的旨在对任何计算机硬件或软件的操作或安全产生不利影响的计算机代码；</p >',
				'<p>(f)攻击、干扰、扰乱或不利影响任何服务、硬件、软件、系统、网站或网络，包括但不限于使用大量自动化手段(包括机器人、爬虫、脚本或类似的数据收集或提取方法)访问或攻击任何服务、硬件、软件、系统、网站或网络；</p >',
				'<p>(g)未经授权访问服务、账户或系统的任何部分,或试图进行上述行为；</p >',
				'<p>(h)违反或不利影响服务、硬件、软件、系统、网站或网络的安全性或完整性；</p >',
				'<p>(i)分发、传播或发送未经请求的信息、促销、广告或招揽（如“商业性信息”）；</p >',
				'<p>(j)欺骗性地提供商品或服务，或任何含有虚假、欺骗或误导性陈述的广告、促销或其他材料。</p >',
				'<p>禁止的内容。 您保证使用本服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，保证您及使用您产品的用户不会利用本服务进行存储、发布、传播如下信息和内容：</p >',
				'<p>(a)反对宪法所确定的基本原则的；</p >',
				'<p>(b)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p >',
				'<p>(c)损害国家荣誉和利益的；</p >',
				'<p>(d)煽动民族仇恨、民族歧视，破坏民族团结的；</p >',
				'<p>(e)破坏国家宗教政策，宣扬邪教和封建迷信的；</p >',
				'<p>(f)散布谣言，扰乱社会秩序，破坏社会稳定的；</p >',
				'<p>(g)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p >',
				'<p>(h)侮辱或者诽谤他人，侵害他人合法权益的；</p >',
				'<p>(i)含有法律、行政法规禁止的其他内容的。</p >',
				'<p>本协议的订立、效力、解释、履行和争议的解决均适用中华人民共和国法律。</p >',
				'<p>双方同意本协议的签订地为南京市，并同意将因本协议引起的或与本协议有关的任何争议提交合同签订地有管辖权的法院诉讼解决（包括与非合同义务有关的任何争议或索赔）。</p >'
			];
			return html.join('');
		}
	}
};
</script>
<template>
    <div id="bottomColumn">
        <div class="layers_1" :style="'background-image:url('+$store.state.imagePath.foot+');'">
            <div class="left">
                <div class="h1">
                    <span>产品大全</span>
                    <span>文档中心</span>
                    <span>关于创数</span>
                </div>
                <div class="h2">
                    <span @click="$router.push({path: '/oneCheckLogin'})">一键登录</span>
                    <span @click="$router.push({path: '/documentCenter/oneCheckLogin/file'})">对接文档</span>
                    <span @click="$router.push({path: '/aboutUs'})">关于我们</span>
                    <span @click="$router.push({path: '/shortMessage'})">短信验证码</span>
                    <span @click="$router.push({path: '/documentCenter/oneCheckLogin/download'})">资源下载</span>
                    <span @click="clauseDialogVisible = true">隐私权政策</span>
                </div>
            </div>
            <div class="right">
                <span>联系我们</span>
                <i :style="'background-image:url('+$store.state.imagePath.qr_code+');'"></i>
            </div>
        </div>
        <div class="layers_2">
            公司地址：江苏省南京市云座中和<br>创数华谷（南京）信息科技有限公司版权所有@2019-2021 AII Right Reserved
          <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #777">苏ICP备2023018103号-1</a>
        </div>
        <el-dialog
                title="隐私权政策"
                :visible.sync="clauseDialogVisible"
                width="1000px"
                custom-class='clause_dialog'
                :append-to-body="true">
            <div class="content" v-html="clauseHTML()"></div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="clauseDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style scoped lang="scss">
    #bottomColumn {
        height: auto; width: 100%; display: block; font-size: 0; background-color: #060710; min-width: 1200px;
        .layers_1 {
            width: 100%; height: 300px; display: block; padding: 72px calc((100% - 1200px) / 2) 60px calc((100% - 1200px) / 2); box-sizing: border-box;
            div.left {
                width: 749px; height: 170px; border-right: 1px solid rgba(255, 255, 255, 0.15); display: inline-block; vertical-align: top; text-align: right;
                .h1 {
                    width: 540px; text-align: left; display: inline-block;
                    span {
                        width: 180px; line-height: 20px; color: #E3E7FA; text-align: left; font-size: 14px; display: inline-block; vertical-align: top; margin-bottom: 40px;
                    }
                }
                .h2 {
                    width: 540px; text-align: left; display: inline-block;
                    span {
                        width: 180px; line-height: 20px; color: #A2A6B6; text-align: left; font-size: 14px; display: inline-block; vertical-align: top; margin-bottom: 20px; cursor: pointer;
                    }
                }
            }
            div.right {
                width: 450px; height: 170px; display: inline-block; vertical-align: top; box-sizing: border-box; padding: 0 220px 0 120px;
                span {
                    width: 110px; line-height: 20px; display: inline-block; color: #E3E7FA; font-size: 14px; text-align: left; margin-bottom: 40px;
                }
                i {
                    width: 110px; height: 110px; background-color: #ffffff; display: inline-block;
                }
            }
        }
        .layers_2 {
            width: 100%; height: 90px; display: block; font-size: 14px; color: #6c7082; line-height: 20px; padding: 25px 0; text-align: center; box-sizing: border-box;
        }
    }
</style>
