<template>
    <div id="oneCheckLogin" ref="box" @scroll="onScroll" @touchmove="onScroll">
        <div class="banner">
            <div class="banner_box_slide auto_padding"
                 :style="'background-image:url('+$store.state.imagePath.banner2+');'">
                <div class="box ">
                    <transition name="fade" mode="out-in">
                        <div class="content">
                            <div class="title">一键登录</div>
                            <div class="info">3秒完成登录注册，用户体验好，转化率高<br>三网整合便捷，一次集成可享受三网验证能力<br>减少开发成本，验证成功才计费</div>
                            <el-button type="primary" class="immediate_use" @click="$router.push({path:'/documentCenter/oneCheckLogin/file'})">立即使用</el-button>
                            <i class="right_img"
                               :style="'background-image:url('+$store.state.imagePath.banner_2+');'"></i>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <!-- 应用场景 -->
        <div class="application_scenarios auto_padding lazy_box">
            <div class="the_title">
                <span class="no_subtitle_main_title">应用场景</span>
            </div>
            <div class="content">
                <ul>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_1+')'"></i>
                        <span class="name">注册登录</span>
                        <span class="tips">3秒完成验证<br>助力拉新转化</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_2+')'"></i>
                        <span class="name">找回密码</span>
                        <span class="tips">快速验证<br>提供安全保障</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_3+')'"></i>
                        <span class="name">手机绑定</span>
                        <span class="tips">三网合一<br>极速完成</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.application_scenarios_4+')'"></i>
                        <span class="name">支付确认</span>
                        <span class="tips">网关验证<br>安全可靠</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 产品优势 -->
        <div class="advantage_product auto_padding lazy_box">
            <div class="the_title">
                <span class="no_subtitle_main_title">产品优势</span>
            </div>
            <div class="content">
                <ul>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_5+')'"></i>
                        <span class="name">三网合一</span>
                        <span class="tips">支持三大运营商<br>一次接入，完成三网<br>降低维护成本</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_6+')'"></i>
                        <span class="name">自定义</span>
                        <span class="tips">产品流程及配置项<br>全程由合作方决定</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_7+')'"></i>
                        <span class="name">稳定保障</span>
                        <span class="tips">集成多家运营商<br>实时监控自动切换</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_8+')'"></i>
                        <span class="name">专业团队</span>
                        <span class="tips">7*24小时<br>人员1V1服务</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 产品价格 -->
        <div class="product_price auto_padding lazy_box">
            <div class="the_title">
                <span class="no_subtitle_main_title">产品价格</span>
            </div>
            <div class="content">
                <ul>
                    <li class="title">
                        <span class="top">预充</span>
                        <div class="center">
                            <i></i>
                        </div>
                        <span class="bottom">单价</span>
                    </li>
                    <li v-for="(item,index) in priceList">
                        <span class="top">{{item.top}}</span>
                        <div class="center">
                            <i :style="'height:'+parseInt((item.bottom / 0.055) * 100)+'%'"></i>
                        </div>
                        <span class="bottom">{{item.bottom}}<em
                                style="font-size: 12px;line-height: 22px">元/条</em></span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 接入指引 -->
        <div class="access_guidelines auto_padding lazy_box"
             :style="'background-image:url('+$store.state.imagePath.comment_bg+')'">
            <div class="the_title">
                <span class="no_subtitle_main_title">接入指引</span>
            </div>
            <div class="content">
                <ul class="step">
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_9+')'"></i>
                        <span class="name" :class="auto_number === 1 ? 'on' : ''">1<i class="line"></i></span>
                        <span class="tips">注册/登录</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_10+')'"></i>
                        <span class="name" :class="auto_number === 2 ? 'on' : ''">2<i class="line"></i></span>
                        <span class="tips">后台创建应用</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_11+')'"></i>
                        <span class="name" :class="auto_number === 3 ? 'on' : ''">3<i class="line"></i></span>
                        <span class="tips">审核通过</span>
                    </li>
                    <li>
                        <i class="icon"
                           :style="'background-image:url('+$store.state.iconPath.advantage_12+')'"></i>
                        <span class="name" :class="auto_number === 4 ? 'on' : ''">4</span>
                        <span class="tips">对接调试</span>
                    </li>
                </ul>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <bottom-column></bottom-column>
        </transition>
    </div>
</template>
<script>
import banner from '../components/banner';
import bottomColumn from '../components/bottomColumn';

export default {
    data () {
        return {
            hot_product_active_index: 'h_1_1',
            myECharts: '',
            priceList: [
                {top: '<500元', bottom: 0},
                {top: '1000元', bottom: 0},
                {top: '5000元', bottom: 0},
                {top: '10000元', bottom: 0},
                {top: '30000元', bottom: 0},
                {top: '50000元', bottom: 0}
            ],
            auto_number: 1,
            si: ''
        };
    },
    computed: {
        box: function () {
            return this.$refs.box;
        }
    },
    components: {banner, bottomColumn},
    mounted () {
        this.onScroll();
    },
    methods: {
        onScroll: function () {
            let _this = this;
            let lazy_box = document.querySelectorAll('.lazy_box');
            this.box.scrollTop > 66 ? this.$store.commit('toggleShowTitleBarBackgroundColor', true) : this.$store.commit('toggleShowTitleBarBackgroundColor', false);
            lazy_box.forEach(function (item) {
                if (item.offsetTop < (_this.box.scrollTop + document.documentElement.clientHeight) - 300) {
                    if (item.classList.value.indexOf('product_price') >= 0 && _this.priceList[0].bottom === 0) {
                        _this.priceList = [
                            {top: '<500元', bottom: 0.055},
                            {top: '1000元', bottom: 0.05},
                            {top: '5000元', bottom: 0.045},
                            {top: '10000元', bottom: 0.04},
                            {top: '30000元', bottom: 0.036},
                            {top: '50000元', bottom: 0.033}
                        ];
                    }
                    console.log(_this.si);
                    if (item.classList.value.indexOf('access_guidelines') >= 0 && !_this.si) {
                        _this.autoChangeActiveIndex();
                    }
                    item.classList.add('on');
                } else {
                    if (item.classList.value.indexOf('product_price') >= 0 && _this.priceList[0].bottom > 0) {
                        _this.priceList = [
                            {top: '<500元', bottom: 0},
                            {top: '1000元', bottom: 0},
                            {top: '5000元', bottom: 0},
                            {top: '10000元', bottom: 0},
                            {top: '30000元', bottom: 0},
                            {top: '50000元', bottom: 0}
                        ];
                    }
                    if (item.classList.value.indexOf('access_guidelines') >= 0 && _this.si) {
                        _this.auto_number = 1;
                        clearInterval(_this.si);
                        _this.si = '';
                    }
                    item.classList.remove('on');
                }
            });
        },
        autoChangeActiveIndex: function () {
            let _this = this;
            this.si = setInterval(function () {
                _this.auto_number = (_this.auto_number + 1 > 4) ? 1 : _this.auto_number + 1;
            }, 3000);
        }
    },
    watch: {},
    destroyed: function () {
        clearInterval(this.si);
    }
};
</script>
<style lang="scss" scoped>
    div#oneCheckLogin {
        width: 100%; min-width: 1200px; height: 100%; display: block; overflow-x: hidden; overflow-y: auto; font-size: 0; position: relative;
        .banner {
            width: 100%; height: 690px; overflow: hidden; position: relative;
            div.banner_box_slide {
                width: 100% !important; height: 100%; background-size: cover; display: inline-block; vertical-align: top; overflow: hidden;white-space: pre-wrap; background-color: #ffffff;position: relative;
                div.box {
                    width: 100%; height: 100%; overflow: hidden; position: relative;
                    div.content {
                        width: calc(100% - 52px);margin-top: 187px;margin-left: 26px;margin-right: 26px;transition: opacity 0.6s;
                        div.title {
                            font-size: 42px;color: #ffffff;letter-spacing: 2px;line-height: 1;
                        }
                        div.info {
                            margin-top: 60px;font-size: 24px;color: #ffffff;letter-spacing: 0.8px;line-height: 1.7;
                        }
                        .immediate_use {
                            font-size: 18px; height: 52px; padding: 13px 43px 13px 47px; margin-top: 60px; letter-spacing: 4px; box-sizing: border-box;
                        }
                        i.right_img {
                            width: 500px;height: 468px;position: absolute;top: 124px;right: 30px;display: inline-block;
                        }
                    }
                    div.content.on {
                        opacity: 1;
                    }
                    div.content.on {
                        opacity: 0;
                    }
                }
            }
        }
        div.the_title {
            position: relative;height: 180px;
            span.bg_font {
                height: 180px;line-height: 180px;position: absolute;top: 0;left: 0;right: 0;text-align: center;font-family: Oswald-DemiBold;font-size: 150px;color: #F8F8F8;
            }
            span.main_title {
                margin-top: 58px;width: 100%;font-size: 32px;font-weight: 600;color: #333333;text-align: center;line-height: 45px;display: inline-block;position: relative;z-index: 1;
            }
            span.subtitle {
                width: 100%;font-size: 24px;color: #666666;text-align: center;line-height: 33px;display: inline-block;position: relative;z-index: 1;
                em {width: 20px;display: inline-block;}
            }
            span.no_subtitle_main_title {
                margin-top: 68px;width: 100%;font-size: 32px;font-weight: 600;color: #333333;text-align: center;line-height: 45px;display: inline-block;position: relative;z-index: 1;
            }
        }
        div.application_scenarios {
            position: relative;
            div.content {
                padding: 0 0;
                ul {
                    width: 100%;height: auto;
                    li {
                        width: 258px;height: auto;display: inline-block;vertical-align: top;background-color: transparent;margin: 0 20px;position: relative;transition-timing-function: ease-out;transition-duration: 0.2s;
                        i.icon {
                            width: 150px;height: 118px;display: block;margin-left: calc((100% - 150px) / 2);
                        }
                        span.name {
                            font-size: 24px;width: 100%;line-height: 33px;text-align: center;margin-top: 34px;display: inline-block;
                        }
                        span.tips {
                            font-size: 14px;width: 100%;height: 88px;line-height: 25px;text-align: center;margin-top: 18px;display: inline-block;
                        }
                    }
                }
            }
        }
        div.advantage_product {
            position: relative;background-image: linear-gradient(to bottom, #FFFFFF, #FBFCFE, #F7F9FC);
            div.content {
                padding: 0 0 120px 0;
                ul {
                    width: 100%;height: auto;
                    li {
                        width: 258px;height: 356px;display: inline-block;vertical-align: top;background-color: #ffffff;margin: 0 20px;position: relative;box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.05);transition-timing-function: ease-out;transition-duration: 0.2s;
                        i.icon {
                            width: 150px;height: 150px;display: block;margin-left: calc((100% - 150px) / 2);margin-top: 28px;
                        }
                        span.name {
                            font-size: 24px;width: 100%;line-height: 33px;text-align: center;margin-top: 14px;display: inline-block;
                        }
                        span.tips {
                            font-size: 14px;width: 100%;line-height: 25px;text-align: center;margin-top: 18px;display: inline-block;
                        }
                    }
                    li:hover {
                        transform: translate3d(0, -16px, 0);
                    }
                }
            }
        }
        div.product_price {
            position: relative;
            div.content {
                padding: 0 0 70px 0;
                ul {
                    width: 100%;height: auto;
                    li {
                        width: 90px;margin: 0 90px 0 0;display: inline-block;vertical-align: top;text-align: center;
                        span.top {
                            color: #333;line-height: 25px;font-size: 18px;
                        }
                        div.center {
                            width: 90px;height: 340px;display: block;background-color: #F7F9FC;margin-top: 20px;margin-bottom: 30px;position: relative;
                            > i {
                                background-color: #2C59F3;display: inline-block;position: absolute;bottom: 0;left: 0;right: 0;transition: height 0.6s;height: 0;
                            }
                        }
                        span.bottom {
                            color: #333;line-height: 25px;font-size: 24px;text-align: center;
                        }
                    }
                    li.title {
                        width: 30px;margin: 0 30px !important;
                        span.top {
                            color: #8C93AD;line-height: 25px;font-size: 18px;
                        }
                        div.center {
                            width: 90px;height: 340px;display: block;background-color: transparent;margin-top: 20px;margin-bottom: 30px;
                            > i {
                                background-color: transparent;
                            }
                        }
                        span.bottom {
                            color: #8C93AD;line-height: 25px;font-size: 18px;
                        }
                    }
                }
            }
        }
        div.access_guidelines {
            position: relative;height: 530px;
            div.content {
                padding: 0 0;
                ul {
                    width: 100%;height: auto;
                    li {
                        width: 258px;height: auto;display: inline-block;vertical-align: top;background-color: transparent;margin: 0 20px;text-align: center;position: relative;transition-timing-function: ease-out;transition-duration: 0.2s;
                        i.icon {
                            width: 118px;height: 118px;display: block;margin-left: calc((100% - 118px) / 2);
                        }
                        span.name {
                            position: relative;width: 36px;height: 36px;line-height: 36px;font-size: 24px;text-align: center;margin-top: 38px;display: inline-block;border: 1px solid #979797;border-radius: 100%;box-sizing: border-box;transition: background-color 0.6s, color 0.6s;overflow: visible;
                            i.line {
                                position: absolute; left: calc(36px + 40px);top: 17px;background-color: #d8d8d8;display: inline-block;width: 182px;height: 2px;
                            }
                        }
                        span.tips {
                            font-size: 18px;width: 100%;height: auto;line-height: 32px;text-align: center;margin-top: 20px;display: inline-block;
                        }
                        span.name.on {
                            background-color: #979797;color: #fff;
                        }
                    }
                }
            }
        }
        .lazy_box {
            div.content {
                transition: opacity 0.3s;opacity: 0;
            }
        }
        .lazy_box.on {
            div.content {
                opacity: 1;
            }
        }
    }
</style>
<style lang="scss">

</style>
