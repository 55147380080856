<template>
    <div id="oneCheckLoginProblem">
        <div class="title">常见问题</div>
        <div class="content">
            <li v-for="(item,index) in d_html" :key="index">
                <p class="p">
                    <span class="no">P：</span>
                    <span class="info">
                        {{item.P}}
                    </span>
                </p>
                <p class="r">
                    <span class="no">R：</span>
                    <span class="info">
                        {{item.R}}
                    </span>
                </p>
            </li>
        </div>
    </div>
</template>
<script>

export default {
    data () {
        return {
            d_html: ''
        };
    },
    computed: {
        document: function () {
            return this.$store.state.documentCenterData;
        }
    },
    components: {},
    mounted () {
        this.d_html = JSON.parse(this.document.authQuery.document);
    },
    methods: {},
    watch: {
        document: {
            deep: true,
            handler: function (newV, oldV) {
                if (this.document.hasOwnProperty('authQuery')) {
                    this.d_html = JSON.parse(this.document.authQuery.document);
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    #oneCheckLoginProblem {
        width: 100%; height: 100%; display: block; overflow: auto; font-size: 0; padding: 0 0; box-sizing: border-box;
        .title {
            padding: 40px 50px; font-size: 24px; width: 100%; line-height: 34px; color: #333; box-sizing: border-box;
        }
        .content {
            width: 100%; height: auto; padding: 10px 50px; box-sizing: border-box;
            li {
                width: 100%; box-sizing: border-box; font-size: 16px; border-bottom: 1px solid #F0F2F5; padding: 16px 0;
                .p {
                    width: 100%; color: #333; line-height: 32px; font-weight: 600;
                    span.no {
                        width: 30px; display: inline-block; vertical-align: top; line-height: 32px;
                    }
                    span.info {
                        width: calc(100% - 30px); display: inline-block; vertical-align: top; line-height: 32px;
                    }
                }
                .r {
                    width: 100%; color: #666; font-size: 14px;
                    span.no {
                        width: 30px; display: inline-block; vertical-align: top; line-height: 32px;
                    }
                    span.info {
                        width: calc(100% - 30px); display: inline-block; vertical-align: top; line-height: 32px;
                    }

                }
            }
        }
    }
</style>
<style lang="scss">

</style>
