<template>
    <div id="shortMessageGuide">
        <div class="title">集成指引</div>
        <div class="content">

        </div>
    </div>
</template>
<script>

export default {
    data () {
        return {
            index: ''
        };
    },
    computed: {},
    components: {},
    mounted () {

    },
    methods: {},
    watch: {}
};
</script>
<style lang="scss" scoped>
    #shortMessageGuide {
        width: 100%; height: 100%; display: block; overflow: auto; font-size: 0;padding: 0 0;box-sizing: border-box;
        .title {
            padding: 40px 50px;font-size: 24px;width: 100%;line-height: 34px;color: #333;box-sizing: border-box;
        }
        .content {
            width: 100%;height: auto;padding: 10px 0;box-sizing: border-box;
        }
    }
</style>
<style lang="scss">

</style>
