import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            meta: {title: '首页'},
            component: require('@/views/index').default
        }, {
            path: '/index',
            name: 'index',
            meta: {title: '首页'},
            component: require('@/views/index').default
        }, {
            path: '/register',
            name: 'register',
            meta: {title: '注册'},
            component: require('@/views/register').default
        }, {
            path: '/oneCheckLogin',
            name: 'oneCheckLogin',
            meta: {title: '一键登录'},
            component: require('@/views/oneCheckLogin').default
        }, {
            path: '/shortMessage',
            name: 'shortMessage',
            meta: {title: '短信'},
            component: require('@/views/shortMessage').default
        }, {
            path: '/aboutUs',
            name: 'aboutUs',
            meta: {title: '关于我们'},
            component: require('@/views/aboutUs').default
        }, {
            path: '/documentCenter',
            name: 'documentCenter',
            meta: {title: '文档中心'},
            component: require('@/views/documentCenter/index').default,
            children: [
                {
                    path: 'oneCheckLogin/guide',
                    name: 'oneCheckLoginGuide',
                    meta: {title: '一键登录-集成指引'},
                    component: require('@/views/documentCenter/index/oneCheckLogin/guide').default
                }, {
                    path: 'oneCheckLogin/file',
                    name: 'oneCheckLoginFile',
                    meta: {title: '一键登录-开发文档'},
                    component: require('@/views/documentCenter/index/oneCheckLogin/file').default
                }, {
                    path: 'oneCheckLogin/download',
                    name: 'oneCheckLoginDownload',
                    meta: {title: '一键登录-资源下载'},
                    component: require('@/views/documentCenter/index/oneCheckLogin/download').default
                }, {
                    path: 'oneCheckLogin/problem',
                    name: 'oneCheckLoginProblem',
                    meta: {title: '一键登录-常见问题'},
                    component: require('@/views/documentCenter/index/oneCheckLogin/problem').default
                }, {
                    path: 'shortMessage/guide',
                    name: 'shortMessageGuide',
                    meta: {title: '短信-集成指引'},
                    component: require('@/views/documentCenter/index/shortMessage/guide').default
                }, {
                    path: 'shortMessage/file',
                    name: 'shortMessageFile',
                    meta: {title: '短信-集成指引'},
                    component: require('@/views/documentCenter/index/shortMessage/file').default
                }, {
                    path: 'shortMessage/download',
                    name: 'shortMessageDownload',
                    meta: {title: '短信-集成指引'},
                    component: require('@/views/documentCenter/index/shortMessage/download').default
                }, {
                    path: 'shortMessage/problem',
                    name: 'shortMessageProblem',
                    meta: {title: '短信-集成指引'},
                    component: require('@/views/documentCenter/index/shortMessage/problem').default
                }
            ]
        }
    ]
});
router.beforeEach(function (to, from, next) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (!to.name) {
        next({
            path: '/'
        });
        return;
    }
    store.commit('updateRouterTransitionStatus', false);
    if (store.state.loading) {
        store.commit('toggleShowLoading', false);
    }
    next();
});

router.afterEach((to, from) => {
    setTimeout(function () {
        store.commit('updateRouterTransitionStatus', true);
    }, 500);
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};

export default router;

