<template>
    <div id="wx" :style="'background-image:url('+$store.state.imagePath.wx+')'">
        <i style="width: 50px;height: 50px;position: absolute;z-index: 100;" @mouseover="showBox = true"
           @mouseout="showBox = false"></i>
        <transition name="fade" mode="out-in">
            <div class="box" v-if="showBox" :style="'background-image:url('+$store.state.imagePath.box+')'">
                <i class="qr_code" :style="'background-image:url('+$store.state.imagePath.qr_code+');'"></i>
                <span>添加商务微信</span>
            </div>
        </transition>
    </div>
</template>
<script>

export default {
    data () {
        return {
            showBox: false
        };
    },
    components: {},
    computed: {},
    mounted: function () {
    },
    methods: {}
};
</script>
<style lang="scss" scoped>
    #wx {
        width: 50px; height: 50px; display: inline-block; box-shadow: 0 10px 15px -2px rgba(181, 197, 251, 1); position: fixed; right: 40px; bottom: 160px; z-index: 100; background-color: #2C59F3; border-radius: 2px; cursor: pointer;
        .box {
            width: 182px; height: 204px; display: inline-block; position: fixed; z-index: 100; padding: 13px 21px 21px 17px; box-sizing: border-box; background-color: transparent;
            right: 80px; bottom: 82px; background-size: 100% 100%;
            i.qr_code {
                width: 120px; height: 120px; display: inline-block; margin-left: 12px; margin-top: 12px;
            }
            span {
                width: 100%; font-size: 12px; color: #666; height: 26px; line-height: 26px; text-align: center; display: inline-block;
            }
        }
    }
</style>
<style>
</style>
