<template>
    <div id="aboutUs" ref="box" @scroll="onScroll" @touchmove="onScroll">
        <div class="banner">
            <div class="banner_box_slide auto_padding"
                 :style="'background-image:url('+$store.state.imagePath.about_us_bg+');'">
            </div>
        </div>
        <div class="aboutUs_box auto_padding">
            <div class="box">
                <div class="content">
                    <div class="title">关于我们</div>
                    <div class="tips">
                      创数华谷成立于2019年，自成立以来以专业的服务，极速响应客户的服务态度赢得众多客户的信赖，目前公司服务的客户数已经达到3000+
                        覆盖汽车、金融、证券、直播、教育等众多行业。为企业提供专业高效优质的服务，降低企业集成成本，致力于为企业服务行业营造热情、专业的市场环境。
                    </div>
                    <div class="title to_me">联系我们</div>
                    <div class="tips to_me">
                        <div class="left">
                            公司名称：创数华谷（南京）信息科技有限公司<br>公司地址：江苏省南京市云座中和
                        </div>
                        <div class="right">
                            <i class="qr_code" :style="'background-image:url('+$store.state.imagePath.qr_code+');'"></i>
                            <span class="qr_code_name">客服微信</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <bottom-column></bottom-column>
        </transition>
    </div>
</template>
<script>
import banner from '../components/banner';
import bottomColumn from '../components/bottomColumn';

export default {
    data () {
        return {};
    },
    computed: {
        box: function () {
            return this.$refs.box;
        }
    },
    components: {banner, bottomColumn},
    mounted () {
    },
    methods: {
        onScroll: function () {
            this.box.scrollTop > 66 ? this.$store.commit('toggleShowTitleBarBackgroundColor', true) : this.$store.commit('toggleShowTitleBarBackgroundColor', false);
        }
    },
    watch: {}
};
</script>
<style lang="scss" scoped>
    div#aboutUs {
        width: 100%; min-width: 1200px; height: 100%; display: block; overflow-x: hidden; overflow-y: auto; font-size: 0; position: relative;
        .banner {
            width: 100%; height: 350px; overflow: hidden; position: relative;
            div.banner_box_slide {
                width: 100% !important; height: 100%; background-size: cover; display: inline-block; vertical-align: top; overflow: hidden; white-space: pre-wrap; background-color: #ffffff; position: relative;
            }
        }
        div.aboutUs_box {
            width: 100%; height: 476px; background-color: #ffffff;
            div.box {
                width: 100%; height: 100%; position: relative;
                div.content {
                    position: absolute; height: 560px; left: 150px; right: 150px; top: -184px; box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.06); background-color: #ffffff;
                    .title {
                        font-size: 24px; line-height: 34px; padding-top: 50px; padding-bottom: 36px; width: 100%; text-align: center; color: #333333;
                    }
                    .tips {
                        font-size: 14px; line-height: 30px; margin: 0 70px; padding: 0 30px 50px 30px; width: calc(100% - 140px); color: #333333; box-sizing: border-box; text-align: justify; text-align-last: center; border-bottom: 1px solid #d8d8d8;
                    }
                    .title.to_me {
                        padding-bottom: 24px;
                    }
                    .tips.to_me {
                        height: 132px; text-align: left; text-align-last: left; border-bottom: 0;
                        div.left {
                            width: 510px; height: 100%; line-height: 36px; text-align: left; box-sizing: border-box; padding: 14px 0 0 150px; vertical-align: top; display: inline-block; font-size: 14px;
                        }
                        div.right {
                            width: calc(100% - 510px); height: 100%; vertical-align: top; display: inline-block; text-align: left;
                            .qr_code {
                                width: 100px; height: 100px; display: block; background-color: #D8D8D8;
                            }
                            .qr_code_name {
                                width: 100px; line-height: 25px; margin-top: 12px; text-align: center; text-align-last: center; display: inline-block; font-size: 14px; color: #333;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<style lang="scss">

</style>
