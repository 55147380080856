<template>
    <div id="index" ref="box" @scroll="onScroll" @touchmove="onScroll">
        <banner></banner>
        <!-- 热门产品 -->
        <div class="hot_product auto_padding lazy_box">
            <div class="the_title">
                <span class="bg_font">HOT PRODUCT</span>
                <span class="main_title">热门产品</span>
                <span class="subtitle">热门推荐<em></em>优先选购</span>
            </div>
            <div class="content">
                <el-tabs v-model="hotProductActiveName" @tab-click="handleHotProductClick">
                    <el-tab-pane label="一键登录" name="h_1" class="hot_product_el_tab_content">
                        <div class="left_box">
                            <div class="title">快速接入，提升体验</div>
                            <div class="info"><i class="No"></i>整合三大运营商网关取号服务，准确识别用户手机号码</div>
                            <div class="info"><i class="No"></i>实现App 登录注册等环节一键通过</div>
                            <div class="info"><i class="No"></i>帮助企业优化验证流程，大幅提升用户体验</div>
                            <div class="info"><i class="No"></i>助力运营拉新、留存、促活</div>
                            <el-button type="primary" class="immediate_use"
                                       @click="$router.push({path:'/documentCenter/oneCheckLogin/file'})">立即使用
                            </el-button>
                        </div>
                        <div class="right_box">
                            <i class="product_image"
                               :style="'background-image:url('+$store.state.imagePath['product_' + auto_number_hot]+')'"></i>
                            <i class="product_image"
                               :style="'background-image:url('+$store.state.imagePath.product_0+')'"></i>
                            <ul class="navigation">
                                <li v-for="(item,index) in [1,2,4,5]"
                                    :style="'background-image:url('+$store.state.imagePath[comment_active_data['c_1_' + item].name]+')'"
                                    :class="hot_product_active_index === ('h_1_' + parseInt(index + 1)) ? 'on' : ''"
                                    @click="handleHotProductActiveIndex(parseInt(index + 1))">
                                    <i class="line"
                                       :class="hot_product_active_index=== ('h_1_' + parseInt(index + 1)) ? 'on' : ''"></i>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="短信验证码/营销短信" name="h_2" class="hot_product_el_tab_content">
                        <transition name="fade" mode="out-in">
                            <div class="left_box">
                                <div class="title">多通道备用，秒级触达</div>
                                <div class="info"><i class="No"></i>联手三大运营商，三网短信通道落地，三网合一</div>
                                <div class="info"><i class="No"></i>多通道备用，保证99.9%成功率</div>
                                <div class="info"><i class="No"></i>支持多种接口，方便网站、APP、系统等通过接口方式与平台对接</div>
                                <el-button type="primary" class="immediate_use"
                                           @click="$router.push({path:'/documentCenter/shortMessage/file'})">立即使用
                                </el-button>
                            </div>
                        </transition>
                        <div class="right_box">
                            <i class="product_image"
                               :style="'background-image:url('+$store.state.imagePath.product_5+')'"></i>
                            <i class="product_image"
                               :style="'background-image:url('+$store.state.imagePath.product_0+')'"></i>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- 产品优势 -->
        <div class="advantage_product auto_padding lazy_box" style="background-color: #F7F9FC">
            <div class="the_title">
                <span class="bg_font" style="color: #F1F4F8;">ADVANTAGE</span>
                <span class="no_subtitle_main_title">产品优势</span>
            </div>
            <div class="content">
                <ul>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_1+')'"></i>
                        <span class="name">便捷体验</span>
                        <span class="tips">快速集成SDK<br>对接简单API</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_2+')'"></i>
                        <span class="name">操作简单的控制台</span>
                        <span class="tips">精准数据<br>账单支持导出</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_3+')'"></i>
                        <span class="name">高效稳定</span>
                        <span class="tips">产品直联运营商，多点备<br>份保障系统稳定</span>
                    </li>
                    <li>
                        <i class="icon" :style="'background-image:url('+$store.state.iconPath.advantage_4+')'"></i>
                        <span class="name">专业支撑</span>
                        <span class="tips">专业的商务，技术团队全<br>天候为客户保驾护航</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 客户点评 -->
        <div class="customer_reviews auto_padding lazy_box"
             :style="'background-image:url('+$store.state.imagePath.comment_bg+')'">
            <div class="the_title">
                <span class="bg_font">COMMENT</span>
                <span class="no_subtitle_main_title">客户点评</span>
            </div>
            <div class="content">
                <i class="y_1">“</i>
                <i class="y_2">”</i>
                <div class="title">{{comment_active_data[comment_active_index].title}}</div>
                <div class="tips">{{comment_active_data[comment_active_index].tips}}</div>
                <ul class="navigation">
                    <li v-for="(item,index) in 6"
                        :style="'background-image:url('+$store.state.imagePath[comment_active_data['c_1_' + (index + 1)].name]+')'"
                        :class="comment_active_index === ('c_1_' + parseInt(index + 1)) ? 'on' : ''"
                        @click="handleCommentActiveIndex('c_1_' + parseInt(index + 1))">
                        <i class="score" :style="'background-image:url('+$store.state.iconPath.score+')'"></i>
                    </li>
                </ul>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <bottom-column></bottom-column>
        </transition>
    </div>
</template>
<script>
import banner from '../components/banner';
import bottomColumn from '../components/bottomColumn';

export default {
    data () {
        return {
            hotProductActiveName: 'h_1',
            hot_product_active_index: 'h_1_1',
            comment_active_index: 'c_1_1',
            auto_number_hot: 1,
            auto_number_comment: 1,
            si_hot: '',
            si_comment: '',
            comment_active_data: {
                c_1_1: {
                    title: 'BAZIRIM',
                    tips: '账单准确可信，可根据我们的需求定期发送账单进行核对，目前零误差。',
                    name: 'logo_1'
                },
                c_1_2: {
                    title: '瓜子二手车',
                    tips: '技术服务很专业，问题解决很迅速，值得信赖',
                    name: 'logo_2'
                },
                c_1_3: {
                    title: '查博士',
                    tips: '技术实力可靠，服务稳定及时。',
                    name: 'logo_3'
                },
                c_1_4: {
                    title: '股票牛',
                    tips: '商务及技术服务确实很好，半夜也会有技术进行及时回复，服务态度点赞！',
                    name: 'logo_4'
                },
                c_1_5: {
                    title: '哈啰摩托',
                    tips: '创数的一键登录集成了三大运营商，为我们解决了分别对接的问题，同时降低了我们验证成本。',
                    name: 'logo_5'
                },
                c_1_6: {
                    title: '毛豆新车网',
                    tips: '挑选了很久，最终还是选择了你们。',
                    name: 'logo_6'
                }
            }
        };
    },
    computed: {
        box: function () {
            return this.$refs.box;
        }
    },
    components: {banner, bottomColumn},
    mounted () {
        this.autoChangeActiveIndex();
        this.onScroll();
    },
    methods: {
        onScroll: function () {
            let _this = this;
            let lazy_box = document.querySelectorAll('.lazy_box');
            this.box.scrollTop > 66 ? this.$store.commit('toggleShowTitleBarBackgroundColor', true) : this.$store.commit('toggleShowTitleBarBackgroundColor', false);
            lazy_box.forEach(function (item) {
                if (item.offsetTop < (_this.box.scrollTop + document.documentElement.clientHeight) - 300) {
                    item.classList.add('on');
                } else {
                    item.classList.remove('on');
                }
            });
        },
        handleHotProductClick: function (tab, event) {
            console.log(tab, event);
        },
        handleHotProductActiveIndex: function (value) {
            this.hot_product_active_index = 'h_1_' + value;
            this.auto_number_hot = value;
        },
        handleCommentActiveIndex: function (value) {
            this.comment_active_index = value;
        },
        autoChangeActiveIndex: function () {
            let _this = this;
            if (this.hotProductActiveName === 'h_1') {
                this.si_hot = setInterval(function () {
                    _this.auto_number_hot = (_this.auto_number_hot + 1 > 4) ? 1 : _this.auto_number_hot + 1;
                    _this.hot_product_active_index = 'h_1_' + _this.auto_number_hot;
                }, 3000);
            } else {
                clearInterval(this.si_hot);
            }
            clearInterval(this.si_comment);
            this.si_comment = setInterval(function () {
                _this.auto_number_comment = (_this.auto_number_comment + 1 > 6) ? 1 : _this.auto_number_comment + 1;
                _this.comment_active_index = 'c_1_' + _this.auto_number_comment;
            }, 3000);
        }
    },
    watch: {
        hotProductActiveName: function () {
            this.autoChangeActiveIndex();
        }
    },
    destroyed: function () {
        clearInterval(this.si_hot);
        clearInterval(this.si_comment);
    }
};
</script>
<style lang="scss" scoped>
    div#index {
        width: 100%; min-width: 1200px; height: 100%; display: block; overflow-x: hidden; overflow-y: auto; font-size: 0; position: relative;
        div.the_title {
            position: relative; height: 180px;
            span.bg_font {
                width: 100%; height: 180px; line-height: 180px; position: absolute; top: 0; left: 0; right: 0; text-align: center; font-family: Oswald-DemiBold; font-size: 150px; color: #F8F8F8;
            }
            span.main_title {
                margin-top: 58px; width: 100%; font-size: 32px; font-weight: 600; color: #333333; text-align: center; line-height: 45px; display: inline-block; position: relative; z-index: 1;
            }
            span.subtitle {
                width: 100%; font-size: 24px; color: #666666; text-align: center; line-height: 33px; display: inline-block; position: relative; z-index: 1;
                em { width: 20px; display: inline-block; }
            }
            span.no_subtitle_main_title {
                margin-top: 68px; width: 100%; font-size: 32px; font-weight: 600; color: #333333; text-align: center; line-height: 45px; display: inline-block; position: relative; z-index: 1;
            }
        }
        div.hot_product {
            position: relative; padding-top: 30px;
            div.content {
                div.hot_product_el_tab_content {
                    margin-left: 230px; width: calc(100% - 230px);
                    div.left_box {
                        width: 50%; height: 100%; display: inline-block; vertical-align: top; animation: leftSlide-in .3s linear; -webkit-animation: leftSlide-in .3s linear; -moz-animation: leftSlide-in .3s linear; -o-animation: leftSlide-in .3s linear;
                        div.title {
                            font-size: 24px; color: #333; line-height: 1; margin-bottom: 40px; margin-top: 75px;
                        }
                        div.info {
                            padding-left: 14px; font-size: 14px; color: #333; letter-spacing: 0.8px; line-height: 1.7; position: relative;
                            i.No {
                                width: 6px; height: 6px; display: inline-block; vertical-align: top; border-radius: 100%; background-color: #2C59F3; position: absolute; left: 0; top: 50%; margin-top: -3px;
                            }
                        }
                        .immediate_use {
                            font-size: 18px; height: 52px; padding: 13px 43px 13px 47px; margin-top: 70px; letter-spacing: 4px; box-sizing: border-box;
                        }
                    }
                    div.right_box {
                        width: 50%; height: 100%; display: inline-block; vertical-align: top; position: relative; animation: rightSlide-in .3s linear; -webkit-animation: rightSlide-in .3s linear; -moz-animation: rightSlide-in .3s linear; -o-animation: rightSlide-in .3s linear;
                        i.product_image {
                            width: 314px; height: 427px; display: block; border: 0; background-color: transparent; position: absolute; z-index: 2; transition: background-image 0.3s;
                        }
                        ul.navigation {
                            position: absolute; top: 43px; left: 305px; z-index: 1;
                            li {
                                width: 70px; height: 70px; display: block; background-color: #d8d8d8; border-radius: 10px; margin: 0 0 22px 0; cursor: pointer; transition: background-color 0.8s, box-shadow 0.3s; position: relative;
                                .line {
                                    width: 0; height: 3px; display: inline-block; position: absolute; left: -170px; top: 34px; background-color: #2C59F3; border-radius: 3px; transition: width 0.3s;
                                }
                                .line.on {
                                    width: 140px;
                                }
                            }
                            li.on {
                                background-color: #2C59F3; box-shadow: 0 10px 15px -2px rgba(148, 150, 155, 0.45);
                            }
                            li:hover {
                                background-color: rgba(44, 89, 243, 0.6); box-shadow: 0 10px 15px -2px rgba(148, 150, 155, 0.45);
                            }
                        }
                    }
                    /* 左进左出 */
                    @keyframes leftSlide-in {
                        0% { transform: translateX(-10%); opacity: 0 }
                        100% { transform: translateX(0%); opacity: 1 }
                    }
                    @-webkit-keyframes leftSlide-in {
                        0% { -webkit-transform: translateX(-10%); opacity: 0 }
                        100% { -webkit-transform: translateX(0%); opacity: 1 }
                    }
                    @-moz-keyframes leftSlide-in {
                        0% { -moz-transform: translateX(-10%); opacity: 0 }
                        100% { -moz-transform: translateX(0%); opacity: 1 }
                    }
                    @-o-keyframes leftSlide-in {
                        0% { -o-transform: translateX(-10%); opacity: 0 }
                        100% { -o-transform: translateX(0%); opacity: 1 }
                    }
                    /* 右进右出 */
                    @keyframes rightSlide-in {
                        0% { transform: translateX(10%); opacity: 0 }
                        100% { transform: translateX(0%); opacity: 1 }
                    }
                    @-webkit-keyframes rightSlide-in {
                        0% { -webkit-transform: translateX(10%); opacity: 0 }
                        100% { -webkit-transform: translateX(0%); opacity: 1 }
                    }
                    @-moz-keyframes rightSlide-in {
                        0% { -moz-transform: translateX(10%); opacity: 0 }
                        100% { -moz-transform: translateX(0%); opacity: 1 }
                    }
                    @-o-keyframes rightSlide-in {
                        0% { -o-transform: translateX(10%); opacity: 0 }
                        100% { -o-transform: translateX(0%); opacity: 1 }
                    }
                }
            }
        }
        div.advantage_product {
            position: relative; padding-top: 30px;
            div.content {
                padding: 68px 0 96px 0;
                ul {
                    width: 100%; height: auto;
                    li {
                        width: 258px; height: 356px; display: inline-block; vertical-align: top; background-color: #ffffff; margin: 0 20px; position: relative; box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.05); transition-timing-function: ease-out; transition-duration: 0.2s;
                        i.icon {
                            width: 150px; height: 150px; display: block; margin-left: calc((100% - 150px) / 2); margin-top: 28px;
                        }
                        span.name {
                            font-size: 24px; width: 100%; line-height: 33px; text-align: center; margin-top: 14px; display: inline-block;
                        }
                        span.tips {
                            font-size: 14px; width: 100%; line-height: 25px; text-align: center; margin-top: 18px; display: inline-block;
                        }
                    }
                    li:hover {
                        transform: translate3d(0, -16px, 0);
                    }
                }
            }
        }
        div.customer_reviews {
            position: relative; height: 678px; background-size: 100% 100% !important; padding-top: 30px; box-sizing: border-box;
            i.y_1 {
                font-size: 160px; position: absolute; top: 82px; left: 260px; width: 64px; height: 64px; display: inline-block; color: #333; font-family: Oswald-DemiBold;;
            }
            i.y_2 {
                font-size: 160px; position: absolute; top: 438px; right: 230px; width: 64px; height: 64px; display: inline-block; color: #333; font-family: Oswald-DemiBold;
            }
            div.content {
                height: 648px; margin-top: -180px; position: relative;
                ul.navigation {
                    position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 1;
                    li {
                        width: 70px; height: 70px; display: block; background-color: #d8d8d8; border-radius: 10px; cursor: pointer; transition: background-color 0.8s, box-shadow 0.3s; position: absolute;
                        i.score {
                            width: 0; height: 0; display: inline-block; position: absolute; top: -30px; left: 20px; transition: width 0.3s, height 0.3s;
                        }
                    }
                    li:nth-child(1) {
                        top: 261px; left: 113px;
                    }
                    li:nth-child(2) {
                        top: 471px; left: 308px;
                    }
                    li:nth-child(3) {
                        top: 174px; left: 872px;
                    }
                    li:nth-child(4) {
                        top: 300px; left: 1011px;
                    }
                    li:nth-child(5) {
                        top: 456px; left: 805px;
                    }
                    li:nth-child(6) {
                        top: 447px; left: 516px; width: 40px !important; height: 40px !important;
                        i.score {
                            top: -36px !important;; left: 0 !important;;
                        }
                    }
                    li.on {
                        background-color: #2C59F3; box-shadow: 0 10px 15px -2px rgba(148, 150, 155, 0.45);
                        i.score {
                            width: 126px; height: 46px;
                        }
                    }
                    li:hover {
                        background-color: rgba(44, 89, 243, 0.6); box-shadow: 0 10px 15px -2px rgba(148, 150, 155, 0.45);
                    }
                }
                div.title {
                    font-size: 24px; color: #333; line-height: 1; top: 270px; left: 0; right: 0; position: absolute; text-align: center;
                }
                div.tips {
                    width: 624px; font-size: 18px; color: #333; line-height: 30px; position: absolute; top: 328px; text-align: center; text-align-last: center; left: 0; right: 0; padding: 0 calc((100% - 624px) / 2);
                }
            }
        }
        .lazy_box {
            div.content {
                transition: opacity 0.3s; opacity: 0;
            }
        }
        .lazy_box.on {
            div.content {
                opacity: 1;
            }
        }
    }
</style>
<style lang="scss">
    .el-tabs {
        margin-top: 33px;
        .el-tabs__nav-wrap {
            display: block; text-align: center;
            .el-tabs__nav-scroll {
                width: 600px; text-align: center; display: inline-block; border-bottom: 1px solid #E9E9E9;
                .el-tabs__nav {
                    float: none; display: inline-block;
                    .el-tabs__item {
                        height: auto; line-height: 32px; font-size: 18px; color: #333; padding: 0 44px; margin-bottom: 20px;
                    }
                    .el-tabs__item.is-active {
                        font-weight: 600;
                    }
                    .el-tabs__active-bar {
                        background-color: #2C59F3; height: 3px;
                    }
                }
            }
        }
        .el-tabs__nav-wrap:after {
            height: 0; background-color: #ffffff;
        }
        .el-tabs__content {
            width: 100%; height: 498px;
        }
    }
</style>
