<template>
    <div id="app">
        <div class="titleColumn zIndex_5" :class="$store.state.isShowTitleBarBackgroundColor ? 'on' : ''"
             v-if="$route.name!=='register'">
            <title-column></title-column>
        </div>
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>
        <wx v-if="$route.name!=='register'"></wx>
        <kf v-if="$route.name!=='register'"></kf>
        <transition name="fade" mode="out-in">
            <div class="jump_login" v-if="isShowJumpLogin">
                <div class="jump_login_box">
                    <i class="el-icon el-icon-close" @click="closeJumpLogin"></i>
                    <span class="title">请选择需要登录的产品</span>
                    <div>
                        <i class="left" @click="toAPP"
                           :style="'background-image:url('+$store.state.imagePath.banner_2+');'">
                        </i>
                        <i class="right" @click="toSMS"
                           :style="'background-image:url('+$store.state.imagePath.banner_1+');'">
                        </i>
                    </div>
                    <div>
                        <span class="left">App一键登录</span>
                        <span class="right">全球短信</span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import wx from './components/wx';
import kf from './components/kf';
import titleColumn from './components/titleColumn';

export default {
	name: 'App',
	data () {
		return {
			isShowKFDialog: false
		};
	},
	created: function () {
		
	},
	watch: {},
	computed: {
		isShowJumpLogin: function () {
			return this.$store.state.isShowJumpLogin
		}
	},
	components: {titleColumn, wx, kf},
	mounted: function () {
		this.Api.get();
	},
	methods: {
		closeJumpLogin: function () {
			this.$store.commit('handleShowJumpLogin', false)
		},
		toSMS: function () {
			window.open('http://sms.sq581.com/login');
		},
		toAPP: function () {
			window.open('http://www.sq581.com/admin');
		}
	}
};
</script>
<style lang="scss" scoped>
    div#app {
        width: 100%; height: 100%; display: block; overflow-x: auto; overflow-y: hidden; position: relative;
        .titleColumn { background-color: transparent; height: 66px; position: absolute; left: 0; width: 100vw; min-width: 1200px; }
        .titleColumn.on { background-color: rgba(2, 5, 26, 0.8); }
        .jump_login {
            width: 100vw; height: 100vh; position: fixed; left: 0; top: 0; z-index: 9999999; background-color: rgba(0, 0, 0, 0.4);
            .jump_login_box {
                width: 540px; height: 420px; background-color: #ffffff; position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%);
                padding: 20px 20px; box-sizing: border-box; border-radius: 4px;
                .el-icon-close {
                    position: absolute; right: 10px; top: 10px; font-size: 16px; cursor: pointer;
                }
                .title {
                    width: 100%; display: inline-block; line-height: 92px; font-size: 16px; text-align: center; color: #343434; font-weight: 600;
                }
                div > i.left {
                    width: 210px; height: 210px; display: inline-block; vertical-align: top; margin: 0 20px; background-color: #FFFFFF; border-radius: 6px; position: relative;
                }
                div > i.right {
                    width: 210px; height: 210px; display: inline-block; vertical-align: top; margin: 0 20px; background-color: #FFFFFF; border-radius: 6px; position: relative;
                }
                div > i {
                    transition: all ease-out 0.3s;
                    background-repeat: no-repeat; background-size: cover; cursor: pointer; border: 1px solid #cccccc; box-sizing: border-box; box-shadow: 0 0 3px #cccccc;
                }
                div > i:hover {
                    transform: scale(1.05);
                }
                div > span.left {
                    width: 210px; height: 58px; line-height: 58px; display: inline-block; vertical-align: top; margin: 0 20px; background-color: #FFFFFF; color: #5d80f8; text-align: center; font-size: 14px;
                }
                div > span.right {
                    width: 210px; height: 58px; line-height: 58px; display: inline-block; vertical-align: top; margin: 0 20px; background-color: #FFFFFF; color: #5d80f8; text-align: center; font-size: 14px;
                }
            }
        }
    }
</style>
