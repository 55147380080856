<template>
    <div id="banner" :class="paginationIndex">
        <swiper :options="swiperOption" ref="bannerSwiper">
            <div class="swiper-slide auto_padding" v-for="(item,index) in bannerList"
                 :style="'background-image:url('+ item.bg +');background-color:' + item.color +';'"
                 :key="index">
                <div class="box">
                    <transition-group name="fade" mode="out-in">
                        <div v-if="index === 1" class="content" :key="'t_' + index">
                            <div class="title">一键登录</div>
                            <div class="info">3秒完成登录注册，用户体验好，转化率高<br>三网整合便捷，一次集成可享受三网验证能力<br>减少开发成本，验证成功才计费</div>
                            <el-button type="primary" class="immediate_use"
                                       @click="$router.push({path:'/documentCenter/oneCheckLogin/file'})">立即使用
                            </el-button>
                            <i class="right_img"
                               :style="'background-image:url('+$store.state.imagePath.banner_2+');'"></i>
                        </div>
                        <div v-if="index === 2" class="content" :key="'t_' + index">
                            <div class="title">全球短信</div>
                            <div class="info">全网全球发送，同时支持最新的虚拟运营商号段<br>一账户配多条通道，保证短信持续稳定发送<br>支持Web/H5/App多端API一键接入
                            </div>
                            <el-button type="primary" class="immediate_use"
                                       @click="$router.push({path:'/documentCenter/shortMessage/file'})">立即使用
                            </el-button>
                            <i class="right_img"
                               :style="'background-image:url('+$store.state.imagePath.banner_1+');'"></i>
                        </div>
                        <div v-if="index === 0" class="content_2" :key="'t_' + index">
                            <div class="title">有诉求，找创数<em>GO</em></div>
                            <div class="info">专业的企业服务</div>
                            <i class="right_img"
                               :style="'background-image:url('+$store.state.imagePath.banner_3+');'"></i>
                        </div>
                    </transition-group>
                </div>
            </div>
        </swiper>
        <div class="swiper-pagination"></div>
    </div>
</template>
<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper';

export default {
	data () {
		let _t = this;
		return {
			swiperOption: {
				effect: 'fade',
				loop: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false
				},
				on: {
					slideChange: function () {
						_t.paginationIndex = 't_' + this.activeIndex;
					}
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					dynamicBullets: true,
					clickable: true
				}
			},
			paginationIndex: 't_1'
		};
	},
	components: {
		swiper: swiper,
		swiperSlide: swiperSlide
	},
	computed: {
		swiper () {
			return this.$refs.bannerSwiper.swiper;
		},
		bannerList: function () {
			return [
				{
					bg: this.$store.state.imagePath.banner4,
					color: '#25184d',
				}, {
					bg: this.$store.state.imagePath.banner2,
					color: '#3135a0',
				}, {
					bg: this.$store.state.imagePath.banner3,
					color: '#1b269a',
				}
			]
		}
	},
	mounted: function () {
		this.$nextTick(() => {
			this.swiper.init();
		})
	},
	methods: {}
};
</script>
<style lang="scss" scoped>
    #banner {
        width: 100%; height: 690px; overflow: hidden; position: relative; min-width: 1200px;
        div.content {
            width: calc(100% - 52px); margin-top: 187px; margin-left: 26px; margin-right: 26px;
            div.title {
                font-size: 40px; color: #ffffff; letter-spacing: 2px; line-height: 1;
            }
            div.info {
                margin-top: 60px; font-size: 24px; color: #ffffff; letter-spacing: 0.8px; line-height: 1.7;
            }
            .immediate_use {
                font-size: 18px; height: 52px; padding: 13px 43px 13px 47px; margin-top: 60px; letter-spacing: 4px; box-sizing: border-box;
            }
            i.right_img {
                width: 500px; height: 468px; position: absolute; top: 124px; right: 30px; display: inline-block;
            }
        }
        div.content_2 {
            width: calc(100% - 52px); margin-top: 262px; margin-left: 26px; margin-right: 26px;
            div.title {
                font-size: 40px; color: #ffffff; letter-spacing: 2px; line-height: 1;
                em {
                    display: inline-block; width: 44px; height: 24px; line-height: 24px; background-color: #1DA2F9; font-size: 14px; text-align: center; border-radius: 3px; margin-left: 10px; vertical-align: top; margin-top: 8px; letter-spacing: 0;
                }
            }
            div.info {
                font-size: 32px; color: #ffffff; letter-spacing: 0.8px; line-height: 1; margin-top: 22px;
            }
            .immediate_use {
                font-size: 18px; height: 52px; padding: 13px 43px 13px 47px; margin-top: 60px; letter-spacing: 4px; box-sizing: border-box;
            }
            i.right_img {
                width: 650px; height: 414px; position: absolute; top: 138px; right: 40px; display: inline-block;
            }
        }
        div.content.on {
            animation: leftSlide-in .3s linear; -webkit-animation: leftSlide-in .3s linear; -moz-animation: leftSlide-in .3s linear; -o-animation: leftSlide-in .3s linear;
        }
        /* 左进左出 */
        @keyframes leftSlide-in {
            0% { transform: translateX(-10%); opacity: 0 }
            100% { transform: translateX(0%); opacity: 1 }
        }
        @-webkit-keyframes leftSlide-in {
            0% { -webkit-transform: translateX(-10%); opacity: 0 }
            100% { -webkit-transform: translateX(0%); opacity: 1 }
        }
        @-moz-keyframes leftSlide-in {
            0% { -moz-transform: translateX(-10%); opacity: 0 }
            100% { -moz-transform: translateX(0%); opacity: 1 }
        }
        @-o-keyframes leftSlide-in {
            0% { -o-transform: translateX(-10%); opacity: 0 }
            100% { -o-transform: translateX(0%); opacity: 1 }
        }
    }

    #banner div.box { width: 100%; height: 100%; overflow: hidden; position: relative; }
</style>
<style>
    #banner .swiper-container { width: 100%; height: 100%; }

    #banner .swiper-container .swiper-wrapper { width: 100%; height: 100%; }

    #banner .swiper-container .swiper-wrapper .swiper-slide {
        width: 100% !important; height: 100%; background-size: cover; display: inline-block; vertical-align: top; overflow: hidden; white-space: pre-wrap; background-color: #ffffff; transform: translate3d(0, 0, 0);
    }

    #banner .swiper-pagination { width: 300px; bottom: 0; left: 50%; margin-left: -150px; position: absolute; }

    #banner .swiper-pagination .swiper-pagination-bullet { width: 12px; height: 12px; margin: 40px 20px; display: inline-block; background-color: #ffffff; border-radius: 100%; transition: width 0.5s ease-in-out; border: 0; }

    #banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active { width: 60px; border-radius: 10px; }
</style>
