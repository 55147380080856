import Vue from 'vue';
import ElementUI from 'element-ui';
import './assets/css/element-variables.scss';
import App from './App';
import router from './router';
import store from './store';
import './assets/css/swiper.min.css';
import './assets/css/reset.css';
import './assets/css/main.css';
import './assets/css/markdown.css';

import * as Utils from './implement';
import * as Api from './api';

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.Utils = Utils;
Vue.prototype.Api = Api;

// 执行自适应适配算法
Utils.getScreenPx();
Utils.watchWindowResize(function () {
    if (window.orientation) {
        setTimeout(function () {
            Utils.getScreenPx();
        }, 300);
    } else {
        Utils.getScreenPx();
    }
});

new Vue({
    router,
    store,
    render: h => h(App),
    mounted () {
        document.dispatchEvent(new Event('render-event'));
    }
}).$mount('#app');
