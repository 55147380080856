<template>
    <div id="shortMessageFile">
        <div class="title">开发文档</div>
        <div class="content markdown-body" v-html="d_html"></div>
    </div>
</template>
<script>
const marked = require('marked');

export default {
    data () {
        return {
            d_html: ''
        };
    },
    computed: {
        document: function () {
            return this.$store.state.documentCenterData;
        }
    },
    components: {},
    mounted () {
        if (this.document) {
          this.d_html = marked(this.document.smsService.document);
        }
    },
    methods: {

    },
    watch: {
        document: {
            deep: true,
            handler: function (newV, oldV) {
                if (this.document.hasOwnProperty('smsService')) {
                    this.d_html = marked(this.document.smsService.document);
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    #shortMessageFile {
        width: 100%; height: 100%; display: block; overflow: auto; font-size: 0; padding: 0 0; box-sizing: border-box;
        * {
            -moz-user-select: text; /* Firefox私有属性 */
            -webkit-user-select: text; /* WebKit内核私有属性 */
            -ms-user-select: text; /* IE私有属性(IE10及以后) */
            -khtml-user-select: text; /* KHTML内核私有属性 */
            -o-user-select: text; /* Opera私有属性 */
            user-select: text; /* CSS3属性 */
        }
        .title {
            padding: 40px 50px; font-size: 24px; width: 100%; line-height: 34px; color: #333; box-sizing: border-box;
        }
        .content {
            width: 100%; height: auto; padding: 10px 50px; box-sizing: border-box;
        }
    }
</style>
<style lang="scss">

</style>
