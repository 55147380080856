<template>
    <div id="register">
        <transition name="fade" mode="out-in">
            <div class="register_box" v-show="showBox">
                <div class="left_box">
                    <i class="image_bg" :style="'background-image:url('+$store.state.imagePath.register_bg+')'"></i>
                    <i class="left_down"
                       :style="'background-image:url('+$store.state.imagePath.register_bg_down+')'"></i>
                    <i class="right_up" :style="'background-image:url('+$store.state.imagePath.register_bg_up+')'"></i>
                </div>
                <transition name="fade" mode="out-in">
                    <div class="right_box">
                        <div class="right">
                            <i class="logo" :style="'background-image:url('+$store.state.imagePath.logo_+')'"></i>
                            <span class="to_back el-icon-arrow-left" @click="toHome">返回首页</span>
                            <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px" @submit.native.prevent
                                     class="demo-ruleForm">
                                <ul class="form">
                                    <el-form-item prop="mobile">
                                        <li :class="no_data_key.indexOf('mobile') > -1 ? 'on' : ''">
                                            <span class="label">手机</span>
                                            <el-input class="l_el_input phone_input" placeholder="请输入手机号"
                                                      v-model="formData.mobile"
                                                      maxlength="11"></el-input>
                                            <el-select v-model="formData.zone" placeholder="请选择" class="zone_select"
                                                       popper-class="zone_select_popper">
                                                <el-option
                                                        v-for="item in zoneOptions"
                                                        :key="item.zone"
                                                        :label="'+' + Math.abs(item.zone)"
                                                        :value="item.zone">
                                                    <span class="left">{{ item.zh }}</span>
                                                    <span class="right">{{'+' + Math.abs(item.zone)}}</span>
                                                    <span class="center">{{item.en}}</span>
                                                </el-option>
                                            </el-select>
                                        </li>
                                    </el-form-item>
                                    <el-form-item prop="code">
                                        <li :class="no_data_key.indexOf('code') > -1 ? 'on' : ''" style="width: 180px;">
                                            <span class="label">验证码</span>
                                            <el-input class="l_el_input" style="width: 134px;" :maxlength="4"
                                                      placeholder="请输入验证码"
                                                      v-model="formData.code"></el-input>
                                            <span class="code_time" v-if="no_time"
                                                  @click="showCodeBox = true">获取验证码</span>
                                            <span class="code_time" v-if="time_over"
                                                  @click="getVerificationCode('GET')">重新发送</span>
                                            <span class="code_time disabled"
                                                  v-if="!no_time && !time_over">重新发送{{timer + 's'}}</span>
                                        </li>
                                    </el-form-item>
                                    <el-form-item prop="user">
                                        <li :class="no_data_key.indexOf('user') > -1 ? 'on' : ''">
                                            <span class="label">账号</span>
                                            <el-input class="l_el_input" placeholder="请输入账号" v-model="formData.user"
                                                      :minlength="6"
                                                      :maxlength="16"></el-input>
                                        </li>
                                    </el-form-item>
                                    <el-form-item prop="pwd">
                                        <li :class="no_data_key.indexOf('pwd') > -1 ? 'on' : ''">
                                            <span class="label">密码</span>
                                            <el-input type="password" class="l_el_input" placeholder="请输入密码"
                                                      :minlength="6"
                                                      :maxlength="16"
                                                      v-model="formData.pwd"></el-input>
                                        </li>
                                    </el-form-item>
                                    <el-form-item prop="accountName">
                                        <li :class="no_data_key.indexOf('accountName') > -1 ? 'on' : ''">
                                            <span class="label">公司</span>
                                            <el-input class="l_el_input" placeholder="请输入公司名称" maxlength="50"
                                                      v-model="formData.accountName"></el-input>
                                        </li>
                                    </el-form-item>
                                    <el-form-item class="clause">
                                        <el-checkbox v-model="isReader">
                                            我已阅读并遵守<i @click="handleOpen">&lt;隐私权政策&gt;</i>
                                        </el-checkbox>
                                    </el-form-item>
                                </ul>
                            </el-form>
                            <div class="register_btn" @click="setRegister" :class="isRegister ? 'on' : ''">注册<i
                                    class="el-icon-loading"
                                    v-show="isRegister"></i></div>
                            <span class="no_account">
                                    已有账号，<em @click="toLogin">点击登录</em>
                                </span>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div class="cover" v-if="showCodeBox">
                <div class="cover_box">
                    <span class="title">图像验证码<i class="el-icon-close" @click="closeImageCodeBox"></i></span>
                    <div class="code_box">
                        <el-form :model="imageCodeFormData" :rules="rules" ref="imageCodeForm" label-width="100px" @submit.native.prevent>
                            <ul class="form">
                                <el-form-item prop="imageCode">
                                    <li>
                                        <span class="label">验证码</span>
                                        <el-input class="l_el_input" style="width: 134px;" :maxlength="4"
                                                  placeholder="请输入验证码"
                                                  v-model="imageCodeFormData.imageCode"></el-input>
                                        <get-code :changeCode.sync='identifyCode'></get-code>
                                    </li>
                                </el-form-item>
                            </ul>
                        </el-form>
                    </div>
                    <el-button type="primary" @click="checkImageCode">确认</el-button>
                </div>
            </div>
        </transition>
        <el-dialog
                title="隐私权政策"
                :visible.sync="clauseDialogVisible"
                width="1000px"
                custom-class='clause_dialog'
                :before-close="handleClose">
            <div class="content" v-html="clauseHTML()"></div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="clauseDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
const moment = require('moment');
import getCode from '@/components/getCode';

export default {
	data () {
		let isPoneAvailable = (rule, value, callback) => {
			let myreg = /^[1][3-9][0-9]{9}$/;
			if (!value) {
				if (this.no_data_key.indexOf('mobile') < 0) {
					this.no_data_key.push('mobile');
				}
				return callback(new Error('请输入手机号'));
			} else if (!myreg.test(value)) {
				if (this.no_data_key.indexOf('mobile') < 0) {
					this.no_data_key.push('mobile');
				}
				return callback(new Error('请输入正确的手机号'));
			} else {
				if (this.no_data_key.indexOf('mobile') > -1) {
					this.no_data_key.splice(this.no_data_key.indexOf('mobile'), 1);
				}
				return callback();
			}
		};
		let isAccountName = (rule, value, callback) => {
			if (!value) {
				if (this.no_data_key.indexOf('accountName') < 0) {
					this.no_data_key.push('accountName');
				}
				return callback(new Error('请输入公司名称'));
			} else {
				if (this.no_data_key.indexOf('accountName') > -1) {
					this.no_data_key.splice(this.no_data_key.indexOf('accountName'), 1);
				}
				return callback();
			}
		};
		let isUser = (rule, value, callback) => {
			if (!value) {
				if (this.no_data_key.indexOf('user') < 0) {
					this.no_data_key.push('user');
				}
				return callback(new Error('请输入账号'));
			} else {
				let reg_2 = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
				let reg_1 = new RegExp(/^[0-9A-Za-z]+$/);
				if (value.length < 6 || value.length > 16) {
					return callback(new Error('账号必须为字母和数字组合，且长度6~16位'));
				}
				if (!reg_1.test(value)) {
					return callback(new Error('账号必须为字母和数字组合，且长度6~16位'));
				}
				if (!reg_2.test(value)) {
					return callback(new Error('账号必须为字母和数字组合，且长度6~16位'));
				}
				if (this.no_data_key.indexOf('user') > -1) {
					this.no_data_key.splice(this.no_data_key.indexOf('user'), 1);
				}
				return callback();
			}
		};
		let isPwd = (rule, value, callback) => {
			if (!value) {
				if (this.no_data_key.indexOf('pwd') < 0) {
					this.no_data_key.push('pwd');
				}
				return callback(new Error('请输入密码'));
			} else {
				let reg_2 = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
				let reg_1 = new RegExp(/^[0-9A-Za-z]+$/);
				if (value.length < 6 || value.length > 16) {
					return callback(new Error('密码必须为字母和数字组合，且长度6~16位'));
				}
				if (!reg_1.test(value)) {
					return callback(new Error('密码必须为字母和数字组合，且长度6~16位'));
				}
				if (!reg_2.test(value)) {
					return callback(new Error('密码必须为字母和数字组合，且长度6~16位'));
				}
				if (this.no_data_key.indexOf('pwd') > -1) {
					this.no_data_key.splice(this.no_data_key.indexOf('pwd'), 1);
				}
				return callback();
			}
		};
		let isCode = (rule, value, callback) => {
			if (!value) {
				if (this.no_data_key.indexOf('code') < 0) {
					this.no_data_key.push('code');
				}
				return callback(new Error('请输入验证码'));
			} else {
				if (this.no_data_key.indexOf('code') > -1) {
					this.no_data_key.splice(this.no_data_key.indexOf('code'), 1);
				}
				return callback();
			}
		};
		let isImageCode = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入验证码'));
			} else {
				if (value.toLowerCase() === this.identifyCode.toLowerCase()) {
					return callback();
				}
				return callback(new Error('验证码错误'));
			}
		};
		return {
			isRegister: false,
			showCodeBox: false,
			identifyCode: '',
			showBox: false,
			timer: 0,
			no_time: true,
			time_over: false,
			si: '',
			formData: {
				accountName: '',
				code: '',
				mobile: '',
				pwd: '',
				user: '',
				zone: 86
			},
			rules: {
				mobile: {required: true, validator: isPoneAvailable, trigger: 'blur'},
				accountName: {required: true, validator: isAccountName, trigger: 'blur'},
				code: {required: true, validator: isCode, trigger: 'blur'},
				pwd: {required: true, validator: isPwd, trigger: 'blur'},
				user: {required: true, validator: isUser, trigger: 'blur'},
				imageCode: {required: true, validator: isImageCode, trigger: 'blur'},
			},
			no_data_key: [],
			zoneOptions: [],
			imageCodeFormData: {
				imageCode: ''
			},
			isReader: false,
			clauseDialogVisible: false,
		};
	},
	components: {
		getCode
	},
	computed: {},
	created: function () {
		this.getZone();
		setTimeout(() => {
			this.showBox = true;
		}, 100);
	},
	mounted: function () {
		let ls_time = localStorage.getItem('code_time');
		if (ls_time) {
			if (parseInt((moment().valueOf() - ls_time) / 1000) < 60) {
				this.timer = 59 - parseInt((moment().valueOf() - ls_time) / 1000);
				this.startsCountdown();
			} else {
				this.timer = 0;
			}
		} else {
			this.no_time = true;
			this.time_over = false;
		}
	},
	methods: {
		toLogin: function () {
			window.open('http://www.sq581.com/admin/');
		},
		toHome: function () {
			this.$router.push({path: '/'});
		},
		getZone: function () {
			this.Api.getZone((data) => {
				if (data.length) {
					this.zoneOptions = this.zoneOptions.concat(data);
				}
			});
		},
		setRegister: function () {
			let _this = this;
			if (this.isReader) {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						_this.Api.setRegister(_this.formData, function () {
							_this.$message.success('注册成功！');
							_this.$router.push({path: '/'});
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			} else {
				this.$message.error('请阅读隐私权政策');
			}
		},
		getVerificationCode: function (name) {
			let myreg = /^[1][3-9][0-9]{9}$/;
			let _this = this;
			if (name === 'GET') {
				if (this.formData.mobile && myreg.test(this.formData.mobile)) {
					this.Api.sendCode({phone: this.formData.mobile, zone: this.formData.zone}, function () {
						_this.$message.success('已发送');
						_this.timer = 59;
						_this.no_time = false;
						_this.time_over = false;
						localStorage.setItem('code_time', moment().valueOf());
						_this.startsCountdown();
					});
				} else {
					this.$message.error('请输入正确的手机号');
				}
			}
		},
		startsCountdown: function () {
			let _this = this;
			this.si = setInterval(function () {
				_this.timer = _this.timer - 1;
			}, 1000);
		},
		checkImageCode: function () {
			this.$refs['imageCodeForm'].validate((valid) => {
				if (valid) {
					this.closeImageCodeBox();
					this.getVerificationCode('GET');
				}
			});
		},
		closeImageCodeBox: function () {
			this.showCodeBox = false;
			this.imageCodeFormData = {
				imageCode: ''
			};
		},
		clauseHTML: function () {
			let html = [
				'<p>在您使用本服务前请充分阅读并理解本协议内容，在创建客户账户时，一旦您选择“同意”按钮，也代表您同意遵守本协议之所有约定并授权创数科技可以通过运营商进行针对个人信息的数据核验。如果您不接受本协议，请不要选择“同意”按钮，也不要进行注册、使用或以其它方式访问我们的服务。</p >',
				'<p>您对在您账户下的所有活动（无论是否获得授权）全权负责，包括您的客户数据及每个客户使用的质量和完整性。如果您的账户下有任何未经授权或滥用行为或者任何与服务相关的安全事件，您将立即通知我们。您认可并同意我们不会对您账户中任何未经授权或滥用行为负责。</p >',
				'<p>您对：（a）您的账户的保密性，以及用于启用您访问服务的任何密码、身份验证密钥或安全凭据的安全性负责;（b）您的员工，用户或允许您向其提供服务的任何第三方使用本服务负责；（c）所有在您账户下的活动，不论是您还是您的最终用户的活动负责。(d) 由于您未使用合理的安全预防措施而导致的任何未经授权的使用负责。</p >',
				'<p>服务限制。 您不得、也不能使得或允许最终用户或其他人（a）修改、变更服务或制造服务的衍生品；（b）对服务进行反汇编、反编译、反向工程、复制服务的任何部分，或应用其他任何程序来获得服务中所包含的任何软件的源代码；（c）分发、转售、再许可、或转让服务，除非本协议另有规定或经双方书面同意。</p >',
				'<p>禁止的行为。 访问或使用本服务，或允许他人访问或使用服务时，不得有下列行为：</p >',
				'<p>(a)违反任何地区、国家或国际上的法律法规及规定；</p >',
				'<p>(b)侵犯或侵害他人权益，包括但不限于隐私权或知识产权；</p >',
				'<p>(c)从事、鼓动、协助或允许他人进行任何违法、非法、侵权、有害或欺诈行为，包括不限于任何下述活动：以任何方式伤害或企图伤害未成年人、色情、非法赌博、违规搭建VPN、庞氏骗局、网络攻击、网络钓鱼或损害、私自拦截任何系统、程序或数据，未经许可监控服务数据或流量；</p >',
				'<p>(d)传输、提供、上传、下载、使用或再使用、散布或分发任何非法、侵权、攻击性、有害的内容或材料，包括但不限于在如下“禁止的内容”中所列的内容或材料；</p >',
				'<p>(e)传输任何数据、发送或上传任何包含病毒、蠕虫、特洛伊木马、网络定时炸弹、间谍软件、广告软件或任何其他有害程序或类似的旨在对任何计算机硬件或软件的操作或安全产生不利影响的计算机代码；</p >',
				'<p>(f)攻击、干扰、扰乱或不利影响任何服务、硬件、软件、系统、网站或网络，包括但不限于使用大量自动化手段(包括机器人、爬虫、脚本或类似的数据收集或提取方法)访问或攻击任何服务、硬件、软件、系统、网站或网络；</p >',
				'<p>(g)未经授权访问服务、账户或系统的任何部分,或试图进行上述行为；</p >',
				'<p>(h)违反或不利影响服务、硬件、软件、系统、网站或网络的安全性或完整性；</p >',
				'<p>(i)分发、传播或发送未经请求的信息、促销、广告或招揽（如“商业性信息”）；</p >',
				'<p>(j)欺骗性地提供商品或服务，或任何含有虚假、欺骗或误导性陈述的广告、促销或其他材料。</p >',
				'<p>禁止的内容。 您保证使用本服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，保证您及使用您产品的用户不会利用本服务进行存储、发布、传播如下信息和内容：</p >',
				'<p>(a)反对宪法所确定的基本原则的；</p >',
				'<p>(b)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p >',
				'<p>(c)损害国家荣誉和利益的；</p >',
				'<p>(d)煽动民族仇恨、民族歧视，破坏民族团结的；</p >',
				'<p>(e)破坏国家宗教政策，宣扬邪教和封建迷信的；</p >',
				'<p>(f)散布谣言，扰乱社会秩序，破坏社会稳定的；</p >',
				'<p>(g)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p >',
				'<p>(h)侮辱或者诽谤他人，侵害他人合法权益的；</p >',
				'<p>(i)含有法律、行政法规禁止的其他内容的。</p >',
				'<p>本协议的订立、效力、解释、履行和争议的解决均适用中华人民共和国法律。</p >',
				'<p>双方同意本协议的签订地为南京市，并同意将因本协议引起的或与本协议有关的任何争议提交合同签订地有管辖权的法院诉讼解决（包括与非合同义务有关的任何争议或索赔）。</p >'
			];
			return html.join('');
		},
		handleClose: function () {
			this.clauseDialogVisible = false;
		},
		handleOpen: function () {
			this.clauseDialogVisible = true;
		},
	},
	watch: {
		timer: function () {
			if (this.timer > 0) {
				this.no_time = false;
				this.time_over = false;
			} else if (this.timer === 0) {
				localStorage.removeItem('code_time');
				clearInterval(this.si);
				this.no_time = false;
				this.time_over = true;
			}
		}
	},
	destroyed: function () {
		localStorage.removeItem('code_time');
		clearInterval(this.si);
	}
};
</script>
<style lang="scss" scoped>
    #register {
        width: 100%; height: 100%; display: block; position: relative; font-size: 0; background-image: linear-gradient(180deg, rgb(29, 54, 151), rgb(24, 90, 213)); text-align: center;
        div.register_box {
            width: 1100px; height: 720px; display: inline-block; position: relative; font-size: 0; margin: calc((100vh - 720px) / 2) 0;
            -webkit-box-shadow: 0 15px 10px -15px #000000;; -moz-box-shadow: 0 15px 10px -15px #000000;; box-shadow: 0 15px 10px -15px #000000;
            div.left_box {
                width: 600px; height: 100%; display: inline-block; vertical-align: top; background-image: linear-gradient(45deg, rgb(29, 54, 151), rgb(24, 90, 213)); position: relative;
                i.image_bg {
                    width: 600px; height: 600px; display: inline-block; vertical-align: top; background-size: 100% 100%; position: absolute; left: 50%; top: 50%; margin-top: -300px; margin-left: -300px; z-index: 1;
                }
                i.left_down {
                    width: 240px; height: 280px; display: inline-block; bottom: 0; left: 0; position: absolute; background-size: cover;
                }
                i.right_up {
                    width: 240px; height: 280px; display: inline-block; top: 0; right: 0; position: absolute; background-size: cover;
                }
            }
            div.right_box {
                width: 500px; height: 100%; min-width: 400px; background-color: #ffffff; display: inline-block; vertical-align: top; text-align: center; position: relative;
                div.right {
                    width: 400px; height: 600px; display: inline-block; text-align: left; position: absolute; left: calc((100% - 400px) / 2); top: 50%; margin-top: -300px;
                    i {
                        width: 123px; height: 60px; display: inline-block; margin-left: 45px;
                        background-size: auto 44px; background-repeat: no-repeat; background-position: left center;
                    }
                    i.logo.test {
                        line-height: 60px; color: #3E76FD; font-size: 28px; font-weight: 600;
                    }
                    span.to_back {
                        height: 60px; line-height: 60px; font-size: 14px; color: #717379; display: inline-block; position: absolute; right: 45px; top: 0; cursor: pointer;
                    }
                    .el-icon-arrow-left:before {
                        margin-right: 6px; font-size: 12px; font-weight: bold;
                    }
                    ul.form {
                        width: 100%; padding: 0 0 0 45px; box-sizing: border-box; margin-top: 6px;
                        li {
                            width: 310px; height: 46px; display: block; margin-top: 30px; border-bottom: 1px solid #CED4E8; position: relative;
                            span.label {
                                width: 46px; height: 46px; line-height: 46px; display: inline-block; font-size: 14px; color: #717379;
                            }
                            .l_el_input {
                                width: calc(100% - 46px); padding: 0 0 0 0; vertical-align: top; height: 46px; border: 0; box-sizing: border-box;
                            }
                            .code_time {
                                cursor: pointer; width: 110px; height: 46px; line-height: 46px; text-align: center; display: inline-block; background: rgb(44, 89, 243); border-radius: 2px; color: #ffffff; font-size: 12px; position: absolute; left: 200px; bottom: 0;
                            }
                            .code_time.disabled {
                                cursor: no-drop; background-color: rgb(207, 211, 224) !important;
                            }
                        }
                        li.on {
                            border-color: #F91F3A;
                            span.label {
                                color: #F91F3A !important;
                            }
                            span.error {
                                line-height: 32px; color: #F91F3A !important; display: block; font-size: 14px;
                            }
                        }
                    }
                    .register_btn {
                        width: 310px; height: 60px; line-height: 60px; display: block; background-color: #2c59f3; color: #ffffff; font-size: 18px; text-align: center; margin-left: 45px; margin-top: 0; cursor: pointer;
                        position: relative;
                        i.el-icon-loading {
                            width: 60px; height: 60px; line-height: 60px; position: absolute; left: 90px; top: 0; margin: 0 0; text-align: center;
                        }
                    }
                    .register_btn.on {
                        background-color: #466df3; cursor: no-drop;
                    }
                    .register_btn:active {
                        background-color: #466df3;
                    }
                    .no_account {
                        line-height: 24px; text-align: center; display: inline-block; width: 100%; font-size: 14px; color: #2C59F3; margin-top: 15px;
                        em {
                            border-bottom: 1px solid #2C59F3; cursor: pointer; font-style: normal;
                        }
                    }
                }
            }
        }
        div.cover {
            position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 100; background-color: rgba(0, 0, 0, 0.4);
            .cover_box {
                background-color: #ffffff; width: 420px; height: 200px; border-radius: 5px; padding: 10px 20px; box-sizing: border-box;
                position: absolute; top: 50%; left: 50%; margin-top: -100px; margin-left: -210px;
                .title {
                    font-size: 16px; color: #343434; width: 100%; height: 30px; line-height: 30px; text-align: left; font-weight: 600; display: inline-block; position: relative;
                    i.el-icon-close {
                        color: #343434; font-size: 20px; position: absolute; right: 0; top: 5px; cursor: pointer;
                    }
                }
                .code_box {
                    width: 290px; height: 46px; display: block; border-bottom: 1px solid #CED4E8; position: relative; text-align: left; margin: 20px auto;
                    ul.form {
                        width: 100%; padding: 0 0; box-sizing: border-box;
                        li {
                            width: 100%; line-height: 1;
                            span.label {
                                width: 46px; height: 46px; line-height: 46px; display: inline-block; font-size: 14px; color: #717379;
                            }
                            .l_el_input {
                                width: calc(100% - 46px); padding: 0 0 0 0; vertical-align: top; height: 46px; border: 0; box-sizing: border-box;
                            }
                        }
                    }

                }
                .code_box.on {
                    border-color: #F91F3A;
                    span.label {
                        color: #F91F3A !important;
                    }
                    span.error {
                        line-height: 32px; color: #F91F3A !important; display: block; font-size: 14px;
                    }
                }
            }
        }
        ::v-deep {
            .el-form {
                .el-form-item.clause {
                    position: relative; margin-top: 30px;
                    .el-checkbox {
                        width: 100%; margin-right: 0;
                        .el-checkbox__input {
                            vertical-align: top; line-height: 1;
                            .el-checkbox__inner {
                                width: 16px; height: 16px; margin-top: 12px;
                            }
                        }
                        .el-checkbox__label {
                            vertical-align: top; line-height: 40px; height: 40px;
                            i {
                                margin: 0 5px; color: #3E76FD; font-weight: 600; display: inline-block; width: auto; line-height: 40px; height: 40px;
                            }
                        }

                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    #register {
        .cover_box {
            .code_box {
                .l_el_input {
                    input {
                        height: 46px; line-height: 46px; border: 0; padding: 0 0 0 28px;
                    }
                }
                .el-form {
                    .el-form-item {
                        margin-bottom: 0 !important;
                        .el-form-item__content {
                            margin-left: 0 !important;
                        }
                    }
                    .el-form-item__error {
                        line-height: 32px; color: #F91F3A !important; display: block; font-size: 14px; padding: 0 0;
                    }
                }
            }
            .el-button {
                width: 290px; margin-top: 20px;
            }
        }

        div.right_box {
            ul.form {
                li {
                    position: relative;
                    .l_el_input {
                        input {
                            height: 46px; line-height: 46px; border: 0; padding: 0 0 0 28px;
                        }
                    }
                    .zone_select {
                        width: 70px; position: absolute; left: 46px; top: 0; background-color: #ffffff;
                        .el-input__inner {
                            width: 45px; height: 46px; line-height: 46px; border: 0; padding: 0 0; overflow: hidden;
                        }
                        .el-input__suffix-inner {
                            display: inline-block; line-height: 1;
                            .el-input__icon {
                                line-height: 46px;
                            }
                        }
                    }
                    .phone_input {
                        input {
                            padding: 0 0 0 70px;
                        }
                    }
                }
            }
            .el-form {
                .el-form-item {
                    margin-bottom: 0 !important;
                    .el-form-item__content {
                        margin-left: 0 !important;
                    }
                }
                .el-form-item__error {
                    line-height: 32px; color: #F91F3A !important; display: block; font-size: 14px; padding: 0 0;
                }
            }
        }
    }
</style>
